import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { appInsights } from '../auth/appInsight';
import { getnewBrokerConnectedLink } from '../api/apiUrls/MySettingsApiUrls';
import { devConsoleLog } from '../utils/utilityFunctions';

const useBroker = (filename) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  devConsoleLog('filename', filename);

  const { accessToken, uniqueId } = useSelector((state) => state.user.user);

  const fetchData = async () => {
    await axios
      .get(getnewBrokerConnectedLink(uniqueId), { headers: { Authorization: 'Bearer ' + accessToken } })
      // .get(getUserBrokerUrl(), { headers: { Authorization: 'Bearer ' + accessToken } })
      .then((res) => {
        setData(res.data);
        // setLoading(false)
        devConsoleLog('data from Broker hook', res.data);
      })
      .catch((error) => {
        devConsoleLog('Broker Error', error);
        setError(true);
        appInsights.trackException({ error: new Error('Brokerrrrr' + filename + error) });
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchData();
  }, []);
  try {
    return { loading, error, data, fetchData };
  } catch (error) {
    appInsights.trackException({ error: new Error('Brokererr' + error) });
  }
};

export default useBroker;
