import { Grid } from '@mui/material';
import React from 'react';
import FreedomSuccessImage from '../../../../Components/SharedComponent/FreedomSuccessImage/FreedomSuccessImage';

const SuccessTransaction = ({ paymentPayMethod }) => {
  return (
    <Grid container item direction="column" alignItems="center" height={'100%'} justifyContent={'center'}>
      <FreedomSuccessImage paymentmethod={paymentPayMethod} />
    </Grid>
  );
};

export default SuccessTransaction;
