import { createTheme, ThemeProvider } from '@mui/material';
import { createContext, useMemo, useState } from 'react';
import { appInsights } from '../auth/appInsight';
import { devConsoleLog } from '../utils/utilityFunctions';
// import { linearGradientToColor } from "../utils/utilityFunctions";

const linearGradientToColor = (scale, color1, percentage1, color2, percentage2) => {
  return `linear-gradient(${scale || '180deg'}, ${color1} ${percentage1 || '0%'}, ${color2} ${percentage2 || '100%'})`;
};

export const ColorModeContext = createContext({
  toggleMode: () => {},
  mode: 'dark'
});
const themeObj = {
  light: {
    background: {
      // default: "#fff"
      // default: "#fafafa"
      default: '#FFFFFF',
      default1: '#FFEFB5',
      default2: '#000000',
      defaultbg: '#FFFFFF',
      wrapperborderTop: '#FFF0C9',
      wrappereBottom: '#FFFBF1',
      wrapperbgTop: '#FFF9E7',
      wrapperwallet: '#F9E0A2',
      profilesilver: '#FDFAF3',
      profilegold: '#FFFFFF',
      profileplatinum: '#FFFFFF',
      matchingbg: '#E7FBFF',
      greencircle: '#FFFFFF',
      yellowcircle: '#FFFFFF',
      bluecircle: '#FFFFFF',
      internalAccountbg: '#F7E6FF',
      tradingAccountbg: '#F2FFF6',
      incomeAccountbg: '#E7FBFF',
      marketcontainerbg: '#81d4fe78',
      speedometerbg: '#92c8ee',
      pendingTabBg: linearGradientToColor('180deg', '#095384', '0%', '#125292', '100%'),
      historicEarningStatus: linearGradientToColor('180deg', '#666666', '0%', '#393939', '100%'),
      onboardTabBg: linearGradientToColor('180deg', '#6FDD74', '0%', '#58A163', '100%'),
      activeTabBg: linearGradientToColor('180deg', '#6FDD74', '100%', '#98E79B', '0%'),
      inactiveTabBg: linearGradientToColor('180deg', '#616161', '0%', '#D4D4D4', '0.01%'),
      usedTabBg: linearGradientToColor('180deg', '#666666', '100%', '#666666', '70%'),
      expiredTabBg: linearGradientToColor('180deg', '#E85062', '100%', '#B84155', '100%'),
      earningexpiredStatus: linearGradientToColor('180deg', '#E85062', '0%', '#B84155', '100%'),
      earningPendingStatus: linearGradientToColor('180deg', '#FF2E2E', '100%', '#FF2E2E', '100%'),
      earningCancelledStatus: linearGradientToColor('180deg', '#DDBE6F', '0%', '#8c6500b3', '100%'),
      settledTabBg: linearGradientToColor('180deg', '#666666', '0%', '#393939', '100%'),
      directAffilaitebg: '#EEC9FF',
      firstLinebg: '#A9F1FF',
      downLinebg: '#b4ffcb',
      pendingAffiliatebg: '#BFDFFF',
      graphGradientColorBg: '#BAB9B9',
      graphGradientColorBg1: '#bab9b900',
      downlineLeftInfoBg: '#22445A',
      affiliatecardbg: linearGradientToColor('180deg', '#092F48', '0%', '#3A586C', '100%')

      // default: "rgba(244, 244, 244, 0.9);"
    },
    icon: {
      iconColor: '#3598DB',
      // iconColor: "#ECC44F",
      iconHoverColor: '#E6B64A',
      iconBgColor: '#FFF8E7',
      iconbgHoverColor: '#FBEDC6',
      switchTrack: '#DADADA',
      iconBackground: '#F4E7C2',
      iconBgColor1: 'rgba(244, 168, 177, 0.2)',
      iconBgColor2: '#CEF2D8',
      iconBgColor3: '#FECFD5'
    },
    button: {
      pendingbtnbgcolor: '#FFE277',
      pendingbtncolor: '#9C7400',
      disablebtncolor: '#FFE8AD',
      buttonhover: '#FFD644',
      tablecomplete: '#248A25',
      tableonboarding: '#2332C6',
      buttonborder: '#EDD99D',
      vouchersilverbtncolor: '#505050',
      vouchergoldbtncolor: '#CEA631',
      voucherplatinumbtncolor: '#C111FF',
      disablecolor: '#DDD3A2',
      buttonbgcolor: linearGradientToColor('180deg', '#095384', '0%', '#437CA1', '100%'),
      start: '#00CC39',
      // start: '#B3F0C4',
      startBorder: '#00CC39',
      pause: '#ECC44F',
      pauseBorder: '#f1d176e6',
      stop: '#e10000',
      // stop: '#E85062',
      stopBorder: '#E85062'
    },
    border: {
      grayBorder: '#737373E5',
      borderColor: '#EFD183', //*secondary yellow*//
      darkborderColor: '#FFD644',
      tabBorderColor: '#f3e9cc',
      walletbordercolor: '#FFE3A2',
      errorborder: '#CD3333',
      borderBottomColor: '#CECECE',
      borderTopAndBottom: '#444444',
      borderStepper: '#e2862e',
      orange: '#e2862e',
      menuDividerBorder: '#717579',
      graphXaxisLine: '#EFE7CD',
      topBarIconBorder: '#ECC44F',
      summaryBorderColor: '#F9E0A2',
      textFieldBorderColor: '#efd183',
      iconBorder: '#F9E0A2',
      personaBorder: '#EFD183',
      // border: "#F6E1A6",
      border: '#3598DB',
      border1: '#F6E1A6',
      border2: '#F6E1A6',
      paymentfieldborder: '#EFD183',
      platinumborder: '#C111FF',
      divider: '#FFECBD',
      livetradegraphborder: '#F2F2F2',
      internalAccountBorder: '#AB23FF',
      newinternalAccountBorder: '#AB23FF',
      tradingAccountBorder: '#56C878',
      newtradingAccountBorder: '#05C88A',
      incomeAccountBorder: '#4FD0EC',
      newincomeAccountBorder: '#96BAFF',
      pendingChipBorder: '#4FD0EC',
      onboardChipBorder: '#188A6E',
      activeChipBorder: '#6FDD74',
      usedChipBorder: '#000000',
      settledChipBorder: '#636363',
      expiredChipBorder: '#E85062',
      inactiveChipBorder: '#B7B7B7',
      directAffiliateBorder: '#AB23FF',
      firstLineBorder: '#4FD0EC',
      downLineBorder: '#56c878',
      pendingAffiliateBorder: '#29bedf',
      tabingGrayColor: '#EBEBEB',
      textFieldBorder: '#DDDDDD',
      downlineLeftInfoBorder: '#4FD0EC',
      containerborder: '#dddddd',
      tabsborder: '#E5E5E5',
      winLinecolor: '#155C8A',
      popupBorder: '#51B3F5',
      directGraphBorder: '#FF7A05',
      earningActiveBorder: '#84E288',
      earningHistoricBorder: '#636363',
      earningExpireBorder: '#E85062',
      earningPendingBorder: '#E85062',
      earningCancelledBorder: '#DDBE6F',
      upgradeAccountIcon: '#ADADAD'
    },
    table: {
      header: '#FBEECA',
      managetable: '#1C1C1C',
      managetablebg: '#EEEEEE',
      headBackground: ' #EAE9E9'
    },
    hoverColor: {
      textFieldHover: '#0000000f',
      cardHover: '#22445A'
      // textFieldHover: "#FAEECB",
      // cardHover: "#FAEECB",
    },
    primary: {
      main: '#3598DB' /** same as primary yellow **/,
      // main: "#ECC44F" /** same as primary yellow **/,
      success: '#56C878',
      successdeep: '#00CC39',
      invitesent: '#00CC39',
      successhover: '#7EF0A0',
      greenhover: '#6BFA96',
      black: '#000000',
      bothblack: '#000000',
      lightblack: '#000',
      used: '#515151',
      black1: '#2E2E2E',
      black2: '#171717',
      white: '#ffffff',
      lightwhite: '#fff',
      whitehover: '#FFF1C7',
      // main: "#E3A33D",
      gray: '#808080',
      lightgray: '#eee',
      red: '#FF0000',
      redhover: '#FF6B6B',
      basic: '#56C878',
      basic1: '#B3E6C2',
      advance: '#EA9E58',
      lightadvance: '#FFDFC2',
      advance1: '#FF7A00',
      advanceboxshadow: '#FFBD80',
      pro: '#3C9CBA',
      pro1: '#D1E8EF',
      proboxshadow: '#9ECEDD',
      supreme: '#9B65DF',
      supreme1: '#E7DAF8',
      supremeboxShadow: '#CDB2EF',
      elite: '#E85062',
      elite1: '#FBD5DA',
      elite2: '#CF293C',
      eliteboxShadow: '#F4A8B1',
      primaryYellow: '#3598DB' /** same as main **/,
      primaryYellowGold: '#ECC44F' /** same as main **/,
      secondaryYellow: '#EFD183', //*secondary yellow implemented on border*//
      textcolor: '#3598DB', //Transaction ID tex
      textcolordiable: '#A9A9A9',
      divdersucees: '#ABE4BC',
      error: '#FF6565',
      voucherColor: '#898989',
      voucherBgWrapper: '#EFF0F7',
      documentdivder: '#EAE3D3',
      onboard: '#D0D3F5',
      chipOnboard: '#2332C6',
      onboardBorder: '#97A0F8',
      complete: '#D0EDD0',
      completeBorder: '#97E298',
      dialogtextfield: '#454545',
      phoneInputbg: '#e1e1e1',
      inputtextfield: '#c7c7c7',
      setpperIconBg: '#333131',
      textFieldFocusColor: '#c7c7c7',
      textFieldBorderRadiusColor: '8.0859',
      viewAllColor: '#555555',
      onBoardTextColor: '#000000',
      affiliateLeg: '#000000',
      radioBg: '#EFD183',
      summarytextColor: '#CC8E21',
      // tableHeadBg: "#FBEECA",
      tableHeadBg: '#22445A',
      downLineArrow: '#F9E0A2',
      grpahDropdown: '#CEA631',
      tabHover: '#22445A',
      leftLeg: '#FDF3D6',
      rightLeg: '#DFFCE8',
      binary: '#3AA75A',
      matching: '#1FAFCF',
      textfieldLable: '#efd183',
      grapDropdownBg: '#ffffff',
      divdercoler: '#F9E0A2',
      paymentfield: '#FDEAB3',
      used1: '#000000',
      expired: '#E85062',
      tabellable: '#8D6018',
      tabellable1: '#8D6018',
      tabellablenew: '#8D6018',
      vouchercardtext: '#3598DB',
      // vouchercardtext: "#8D6018",
      radiogroupbg: '#3598DB',
      // radiogroupbg: "#ECC44F",
      investmentDialogamount: '#00922B',
      winGraphArrow: '#00CC39',
      // winGraphArrow: '#D0EDD0',
      lossGraphArrow: '#41060E',
      // lossGraphArrow: '#FBD5DA',
      tradedetails: '#F6F6F6',
      placeholder: '#A9A0A9',
      disableplaceholder1: '#A9A9A9',
      disableplaceholder: '#666666',
      investmentnotifccolor: 'rgba(236, 196, 79, 0.05)',
      pepperstonebtn: '#0064FA',
      pepperstonetext: '#0064FA',
      pepperstonehover: '#E5EFFD',
      icmarketbtn: '#34E834',
      icmarkettext: '#34E834',
      icmarkethover: '#EBFCE8',
      feeBoxbg: '#FDF3D6',
      rankcolor: '#3598DB',
      // rankcolor: "#8D6018",
      internalAccountgraph: '#B454FF',
      tradingAccountgraph: '#00CC39',
      incomeAccountgraph: '#169FBD',
      banknotesbg: '#E3CBEE',
      banknotesbr: '#AB23FF',
      paymentdetailbg: '#22445A',
      emptyProduct: '#F5FAFD',
      piechartColor: '#51F55730',
      importantNote: '#AED6F1',
      paymentsummarycolor: '#999999',
      emailTemplate: '#737373',
      donutChartColor: '#CECECE',
      brokerIconbg: '#FFFFFF',
      sideBarArrow: '#acacbe',
      exclusivebgbutton: '#00A894',
      vipbgbutton: '#DF784D'
    },
    topbar: {
      // barBgColor: "#FDFAF3",
      barBgColor: linearGradientToColor('246.73deg', '#092F48', '34.96%', '#092F48', '115.43%')
    },
    text: {
      dialogtextcolor: '#CC8E21',
      summarycolor: '#CC8E21',
      description: '#000000'
    },
    bottombar: {
      // barBgColor: " #ECC44F", //*secondary yellow implemented on BottomBackground*//
      barBgColor: linearGradientToColor('247.24deg', '#092F48', '35.22%', '#092F48', '72.65%')
    },
    tooltip: {
      backgroundcolor: '#FDEAB3',
      backgroundcolor1: '#102E42',
      // backgroundcolor1: "#FDF3D6",
      bordercolor: '#8D6018',
      bordercolor1: '#2C2C2C'
      // bordercolor1: "#ECC44F",
    }
  },
  dark: {
    background: {
      default: '#000000',
      default1: '#453506',
      default2: '#FFFFFF',
      defaultbg: '#FFFFFF',
      main: '#15283b',
      box: '#333333 !important',
      wrapperborderTop: '#FFF0C9',
      wrappereBottom: '#FFFBF1',
      wrapperbgTop: '#FFF9E7',
      wrapperwallet: '#453506',
      matchingbg: '#203B49',
      greencircle: '#C3E2CC',
      yellowcircle: '#F9E0A2',
      bluecircle: '#203B49',
      silver: '#D5D5D5',
      profilesilver: '#FDFAF3',
      gold: '#FFF3D2',
      profilegold: '#FFFFFF',
      platinum: '#FFECFB',
      profileplatinum: '#FFFFFF',
      internalAccountbg: '#2D0E41',
      tradingAccountbg: '#56c87833',
      incomeAccountbg: '#4fd0ec33',
      marketcontainerbg: '#14232d',
      speedometerbg: '#688899',
      historicEarningStatus: linearGradientToColor('180deg', '#666666', '0%', '#393939', '100%'),
      pendingTabBg: linearGradientToColor('180deg', '#3598DB', '0%', '#235786', '100%'),
      onboardTabBg: linearGradientToColor('180deg', '#6FDD74', '0%', '#448152', '100%'),
      activeTabBg: linearGradientToColor('180deg', '#6FDD74', '0%', '#448152', '100%'),
      inactiveTabBg: linearGradientToColor('180deg', '#616161', '0%', '#D4D4D42', '0.01%'),
      usedTabBg: linearGradientToColor('180deg', '#666666', '0%', '#393939', '100%'),
      expiredTabBg: linearGradientToColor('180deg', '#B84155', '0%', '#E85062', '100%'),
      earningexpiredStatus: linearGradientToColor('180deg', '#E85062', '0%', '#B84155', '100%'),
      earningPendingStatus: linearGradientToColor('180deg', '#FF2E2E', '100%', '#FF2E2E', '100%'),
      earningCancelledStatus: linearGradientToColor('180deg', '#DDBE6F', '0%', '#8c6500b3', '100%'),
      settledTabBg: linearGradientToColor('180deg', '#666666', '0%', '#393939', '100%'),
      directAffilaitebg: '#2D0E41',
      firstLinebg: '#241F58',
      downLinebg: '#41ba6533',
      pendingAffiliatebg: '#29bedf33',
      graphGradientColorBg: '#7B7B7B',
      graphGradientColorBg1: '#ababab00',
      downlineLeftInfoBg: '#22445A',
      affiliatecardbg: linearGradientToColor('180deg', '#254053', '0%', '#212C33', '100%')
    },
    // icon: {
    iconColor: '#CEA631',
    // 	iconHoverColor: '#E6B64A',
    // 	iconBgColor: '#FFF8E7',
    // 	iconbgHoverColor: '#FBEDC6'
    // },
    // border: {
    // 	borderColor: '#EFD183' //*secondary yellow*//
    // },
    topbar: {
      // barBgColor: "#000000",
      barBgColor: linearGradientToColor('180deg', '#254154', '', '#192228', '')
    },
    bottombar: {
      // barBgColor: " #ECC44F", //*secondary yellow implemented on BottomBackground*//
      barBgColor: linearGradientToColor('180deg', '#102C3F', '', '#081720', '')
    },
    icon: {
      iconColor: '#3598DB',
      // iconColor: "#ECC44F",
      iconHoverColor: '#E6B64A',
      iconBgColor: '#FFF8E7',
      iconbgHoverColor: '#FBEDC6',
      iconBgColor1: 'rgba(244, 168, 177, 0.2)',
      switchTrack: '#DADADA',
      iconBackground: '#ECC44F'
    },
    button: {
      pendingbtnbgcolor: '#FFE277',
      pendingbtncolor: '#ffffff',
      tablecomplete: '#248A25',
      vouchersilverbtncolor: '#505050',
      vouchergoldbtncolor: '#CEA631',
      voucherplatinumbtncolor: '#C111FF',
      disablecolor: '#453500',
      buttonbgcolor: linearGradientToColor('180deg', '#3598DB', '0%', '#266087', '100%'),
      start: '#47d45a',
      startBorder: '#00CC39',
      // pause: '#efec06',
      pauseBorder: '#efec06',
      pause: '#ecc44f',
      // pause: '#ECC44F',
      // pauseBorder: '#f1d176e6',
      stop: '#e10000',
      stopBorder: '#e10000'
    },
    border: {
      grayBorder: '#707070',
      borderColor: '#A7831C', //*secondary yellow*//
      darkborderColor: '#FFD644',
      tabBorderColor: '#f3e9cc',
      borderBottomColor: '#2C2C2C',
      orange: '#e2862e',
      graphXaxisLine: '#231B00',
      topBarIconBorder: '#EECC66',
      textFieldBorderColor: '#896500',
      iconBorder: '#ECC44F',
      personaBorder: '#453506',
      // border: "#453506",
      border: '#3598DB',
      border1: '#F6E1A6',
      border2: '#70550B',
      paymentfieldborder: '#2B1E00',
      platinumborder: '#C111FF',
      divider: '#231B00',
      livetradegraphborder: '#231B00',
      internalAccountBorder: '#AB23FF',
      newinternalAccountBorder: '#C72BFE',
      tradingAccountBorder: '#09FF52',
      newtradingAccountBorder: '#07FC11',
      incomeAccountBorder: '#5FE2FF',
      newincomeAccountBorder: '#96BAFF',
      pendingChipBorder: '#29BEDF',
      onboardChipBorder: '#40D5B1',
      activeChipBorder: '#84E288',
      usedChipBorder: '#636363',
      expiredChipBorder: '#E85062',
      settledChipBorder: '#636363',
      inactiveChipBorder: '#B7B7B7',
      directAffiliateBorder: '#B454FF',
      firstLineBorder: '#5142FF',
      downLineBorder: '#4FB76F',
      pendingAffiliateBorder: '#29BEDF',
      tabingGrayColor: '#444444',
      textFieldBorder: '#2C2C2C',
      downlineLeftInfoBorder: '#29BEDF',
      containerborder: '#2C2C2C',
      tabsborder: '#2C2C2C',
      winLinecolor: '#3598DB',
      popupBorder: '#2C2C2C',
      directGraphBorder: '#FF7A05',
      earningActiveBorder: '#84E288',
      earningHistoricBorder: '#636363',
      earningPendingBorder: '#E85062',
      earningExpireBorder: '#E85062',
      earningCancelledBorder: '#DDBE6F',
      upgradeAccountIcon: '#ADADAD'
    },
    table: {
      header: '#FBEECA',
      headBackground: '#232121'
    },
    hoverColor: {
      textFieldHover: '#171717',
      cardHover: '#102E42'
      // cardHover: "#453506",
    },
    text: {
      summarycolor: '#3598DB',
      // summarycolor: "#ECC44F",
      description: '#ADADAD'
    },
    tooltip: {
      backgroundcolor: '#453506',
      backgroundcolor1: '#22445A',
      // backgroundcolor1: "#FDEAB3",
      bordercolor: '#453506',
      bordercolor1: '#ADADAD'
      // bordercolor1: "#8D6018",
    },
    primary: {
      // main: '#E3A33D',
      // success: '#00CC39',
      // basic: '#56C878',
      // advance: '#EA9E58',
      // pro: '#3C9CBA',
      // supreme: '#9B65DF',
      // elite: '#E85062',
      // primaryYellow: '#ECC44F',
      // secondaryYellow: '#EFD183' //*secondary yellow implemented on border*//
      main: '#3598DB' /** same as primary yellow **/,
      // main: "#E3A33D" /** same as primary yellow **/,
      success: '#00CC39',
      successdeep: '#00CC39',
      invitesent: '#FFFFFF',
      successhover: '#7EF0A0',
      greenhover: '#6BFA96',
      black: '#000000',
      bothblack: '#000000',
      lightblack: '#000',
      used: '#515151',
      black1: '#2E2E2E',
      black2: '#171717',
      white: '#ffffff',
      lightwhite: '#fff',
      whitehover: '#FFF1C7',
      // main: "#E3A33D",
      gray: '#808080',
      lightgray: '#eee',
      red: '#FF0000',
      redhover: '#FF6B6B',
      basic: '#56C878',
      basic1: '#B3E6C2',
      advance: '#EA9E58',
      lightadvance: '#FFDFC2',
      advance1: '#FF7A00',
      advanceboxshadow: '#FFBD80',
      pro: '#3C9CBA',
      pro1: '#D1E8EF',
      proboxshadow: '#9ECEDD',
      supreme: '#9B65DF',
      supreme1: '#E7DAF8',
      supremeboxShadow: '#CDB2EF',
      elite: '#E85062',
      elite1: '#FBD5DA',
      elite2: '#CF293C',
      eliteboxShadow: '#F4A8B1',
      primaryYellow: '#3598DB' /** same as main **/,
      primaryYellowGold: '#ECC44F',
      secondaryYellow: '#EFD183', //*secondary yellow implemented on border*//
      textcolor: '#3598DB', //Transaction ID tex
      textcolordiable: '#A9A9A9',
      divdersucees: '#ABE4BC',
      error: '#FF6565',
      voucherColor: '#898989',
      voucherBgWrapper: '#EFF0F7',
      documentdivder: '#EAE3D3',
      onboard: '#D0D3F5',
      chipOnboard: '#2332C6',
      onboardBorder: '#97A0F8',
      complete: '#D0EDD0',
      completeBorder: '#97E298',
      dialogtextfield: '#454545',
      phoneInputbg: '#e1e1e1',
      inputtextfield: '#c7c7c7',
      setpperIconBg: '#333131',
      textFieldFocusColor: '#c7c7c7',
      textFieldBorderRadiusColor: '8.0859',
      viewAllColor: '#555555',
      darkBorderColorWrapper: '#644A00',
      onBoardTextColor: '#FFFFFF',
      affiliateLeg: '#ECC44F',
      radioBg: '#ECC44F',
      summaryBorderColor: '#231B00',
      summarytextColor: '#ffffff',
      // tableHeadBg: "#2B1E00",
      tableHeadBg: '#102E42',
      downLineArrow: '#F9E0A2',
      grpahDropdown: '#CEA631',
      tabHover: '#102E42',
      leftLeg: '#413203',
      rightLeg: '#063714',
      binary: '#41BA65',
      matching: '#29BEDF',
      textfieldLable: '#CEA631',
      grapDropdownBg: '#0b0b0b',
      divdercoler: '#453506',
      paymentfield: '#453506',
      used1: '#666666',
      expired: '#D6182E',
      tabellable: '#ffffff',
      tabellablenew: '#CEA631',
      vouchercardtext: '#3598DB',
      // vouchercardtext: "#ECC44F",
      radiogroupbg: '#2B1E00',
      investmentDialogamount: '#00922B',
      winGraphArrow: '#194119',
      lossGraphArrow: '#41060E',
      tradedetails: '#151515',
      placeholder: '#666666',
      disableplaceholder: '#A9A9A9',
      disableplaceholder1: '#666666',
      investmentnotifccolor: 'rgba(236, 196, 79, 0.05)',
      pepperstonebtn: '#0064FA',
      pepperstonetext: '#3598DB',
      // pepperstonetext: "#ECC44F",
      pepperstonehover: '#000A19',
      icmarketbtn: '#34E834',
      icmarkettext: '#3598DB',
      // icmarkettext: "#ECC44F",
      icmarkethover: '#051705',
      feeBoxbg: '#201900',
      rankcolor: '#3598DB',
      // rankcolor: "#ECC44F",
      internalAccountgraph: '#B454FF',
      tradingAccountgraph: '#09FF52',
      incomeAccountgraph: '#5FE2FF',
      banknotesbg: '#450E66',
      banknotesbr: '#AB23FF',
      paymentdetailbg: '#292929',
      emptyProduct: '#192D3A',
      piechartColor: '#51F55730',
      importantNote: '#153D58',
      paymentsummarycolor: '#999999',
      emailTemplate: '#ffffff',
      donutChartColor: '#CECECE',
      brokerIconbg: '#2C2C2C',
      sideBarArrow: '#666',
      exclusivebgbutton: '#00A894',
      vipbgbutton: '#DF784D'
    }
  }
};

export const ColorContextProvider = ({ children }) => {
  const [mode, setMode] = useState('dark');

  devConsoleLog('before Toggle', mode);
  const colorMode = useMemo(
    () => ({
      toggleMode: (m) => {
        console.log(m);
        setMode((prevMode) => (m === 'light' ? 'light' : 'dark'));
        devConsoleLog('after Toggle', mode);
      },
      mode
    }),
    [mode]
  );

  const theme = useMemo(
    () =>
      createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xll: 1920,
            xlll: 2560,
            '4xl': 3800,
            '5xl': 4500
          }
        },
        components: {
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundImage: 'none'
              }
            }
          },
          MuiCssBaseline: {
            styleOverrides: {
              body: {
                // maxWidth: '1920px',
                // width: '100%',
                // backgroundImage: 'radial-gradient(circle, rgba(234,158,88,0.28644961402529767) 0%, rgba(234,158,88,0) 100%)',
                background:
                  mode === 'light'
                    ? '#ffffff'
                    : // linearGradientToColor("360deg", "#fff8e7", "", "#ffffff", "")
                      //  "linear-gradient("360deg", #fff8e7 0%, #ffffff 100%)"
                      linearGradientToColor('', '#141414', '', '#000000', ''),
                //  "linear-gradient(180deg, #141414 0%, #000000 100%)",
                // background: 'black',
                // backgroundImage: 'radial-gradient(circle, rgba(234,158,88,0.28644961402529767) 0%, rgba(234,158,88,0) 100%)',
                backgroundPosition: '50% 60%',
                //   backgroundColor: '#FAACA8',
                //   backgroundImage: `linear-gradient(19deg, #FAACA8 0%, #DDD6F3 100%)`,
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                backgroundColor: mode === 'light' ? '#ffffff' : '#000000'
              }
            }
          }
        },
        typography: {
          fontFamily: `"Montserrat", sans-serif`
        },
        palette: {
          mode: mode,
          ...themeObj[mode]
        }
      }),
    [mode]
  );

  // const theme = createTheme({
  //     palette: {
  //         mode: mode
  //     },
  // });
  try {
    return (
      <ColorModeContext.Provider value={{ colorMode, setMode }}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ColorModeContext.Provider>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};
