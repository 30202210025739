import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import { images } from '../../../url/SvgLinks';
import { ColorModeContext } from '../../../store';
import { appInsights } from '../../../auth/appInsight';
import { devConsoleLog } from '../../../utils/utilityFunctions';
import InvestmentModel from '../InvestmentModel.js/InvestmentModel';
import { setIsActionUpdate, updateMyPlan } from '../../../features/investment/productsSlice';
import { CTraderAccountReconciliation } from '../../../utils/tradingConfig';
import CloseIcon from '../../../Components/SharedComponent/CloseIcon/CloseIcon';
import FreedomSnackBar from '../../../Components/SharedComponent/FreedomSnackBar';
import { selectSnackbar, showSnackbar } from '../../../features/snackbar/snackbarSlice';
import FreedomPanel from '../../../Components/SharedComponent/FreedomPanel/FreedomPanel';
import FreedomSwitch from '../../../Components/SharedComponent/FreedomSwitch/FreedomSwitch';
import FreedomLinearLoader from '../../../Components/FreedomLinearLoader/FreedomLinearLoader';
import FreedomButton1 from '../../../Components/SharedComponent/FreedomButton1/FreedomButton1';
import useDataJson from '../../../freedomHooks/useDataJson';

const lightProductSelect = images.lightProductSelect;
const darkProductSelect = images.darkProductSelect;

const Description = styled(Box)(({ theme }) => ({
  fontSize: '18px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px'
  }
}));

let firstcall = false;
const TradingMarketsContainer = ({ setIsMarkets, productdashboard = false, selectedProduct, userProductId=0 }) => {
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);
  const { jsonPrefix } = useDataJson();
  const [load, isLoading] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { open } = useSelector(selectSnackbar);
  const markets = useSelector((state) => state.investmentProducts?.markets);
  const { isActionUpdate } = useSelector((state) => state.investmentProducts.productSettings);
  const [state, setState] = useState(markets);
  const [openDialog, setOpenDialog] = useState(false);
  const [resetStates, setRestStates] = useState(false);
  const { accessToken, uniqueId } = useSelector((state) => state.user.user);
  const [marketsStatus, setMarketsStatus] = useState(markets);
  const theme = useTheme();
  const mobile360 = useMediaQuery(theme.breakpoints.down('sm'));
  const tabmd = useMediaQuery(theme.breakpoints.down('md'));
  const laptop = useMediaQuery(theme.breakpoints.down('lg'));
  const desktop = useMediaQuery(theme.breakpoints.down('xl'));

  // const productOverview = useSelector((state) => state.investmentProducts.productOverview);
  // let userProductId = productOverview.userProductId;

  devConsoleLog("selected product", selectedProduct)
  const getMarkets = async () => {
    try {
      const mark = await axios.get(`${CTraderAccountReconciliation()}/api/Lookups/GetMarketsByUserProductsId?userproductid=${userProductId}`, { headers: { Authorization: 'Bearer ' + accessToken } });
      devConsoleLog('user markets', mark.data);
      if (mark.data.length > 0) {
        setState(
          mark.data.map((p, idx) => {
            let filteredId = mark.data.map((obj, index) => {
              return obj.marketId === p.marketId && obj.id !== 0 && obj.id;
            });
            return {
              ...p,
              active: mark.data?.some((obj, index) => {
                return obj.marketId === p.marketId ? obj.active : false;
              }),
              Id: filteredId[idx]
            };
          })
        );
      } else {
        firstcall = true;

        const allActiveFalse = mark.data && mark.data.every((market) => market.active === false);
        if (allActiveFalse) {
          devConsoleLog('All Active properties are false.');
          // activeAllMarketsHandler();
        } else {
          devConsoleLog('Not all Active properties are false.');
        }
      }
      isLoading(false);
    } catch (error) {
      devConsoleLog(error);
    } finally {
      isLoading(false);
    }
  };

  useEffect(() => {
    if(selectedProduct?.productTypeID === 1) {
      getMarkets();
    }else{
      isLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, id) => {
    setState(state.map((p) => (p.marketId === id ? { ...p, active: event.target.checked } : p)));
  };

  const switchTester = () => {
    const allInactive = state.map((obj) => obj.active).every((active) => !active);
    if (!allInactive) {
      let apiCall = false;
      state.map((item) => (item.active === false ? (setOpenDialog(true), (apiCall = true)) : ''));
      if (apiCall === false) {
        submissionHandler();
      }
    } else {
      dispatch(showSnackbar({ message: `${t('You have to choose atleast one market')}`, severity: 'warning' }));
    }
  };

  const fetchMarkets = async (data) => {
    setMarketsStatus(data);
    if (data.length) {
      setState(
        state.map((p, idx) => {
          let filteredId = data.map((obj, index) => {
            return obj.marketId === p.MarketID && obj.id !== 0 && obj.id;
          });
          return {
            ...p,
            Active: data?.some((obj, index) => {
              return obj.marketId === p.MarketID ? obj.active : false;
            }),
            Id: filteredId[idx]
          };
        })
      );
    }
  };

  const submissionHandler = () => {
    const myJSON = { ...jsonPrefix, userId: uniqueId, userProductId: userProductId, userMarkets: state };
    const secondJSON = JSON.stringify(myJSON);
    axios({
      url: `${CTraderAccountReconciliation()}/api/FreedomMyPlan/UpdateUserMarkets`,
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + accessToken
      },
      method: 'post',
      data: myJSON
    })
      .then((res) => {
        dispatch(showSnackbar({ message: `${t('Selected markets are saved.')}`, severity: 'info' }));
        setOpenDialog(false);
        // fetchMarkets(res?.data?.data?.markets);
        // dispatch(updateMyPlan(res?.data?.data))
        dispatch(setIsActionUpdate(!isActionUpdate));
      })
      .catch((error) => {
        appInsights.trackException({ error: new Error(error?.response) });
        dispatch(showSnackbar({ message: `${t('Error occoured during data posting')}`, severity: 'error' }));
        setOpenDialog(false);
      });
  };

  try {
    return (
      <>
        {openDialog && (
          <InvestmentModel setOpenDialog={setOpenDialog} openDialog={openDialog}>
            <CloseIcon
              padding={'0px'}
              handleonclick={() => {
                setOpenDialog(false);
                setRestStates(!resetStates);
              }}
            />
            <Grid container justifyContent={'center'} spacing={2}>
              <Grid
                item
                sx={{
                  fontWeight: 700,
                  fontSize: '24px',
                  lineHeight: '36px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                textalign="center"
                mb={1}
              >
                <img
                  src={mode === 'light' ? lightProductSelect : darkProductSelect}
                  alt="alert-icon"
                  style={{
                    width: '22px',
                    height: '22px',
                    marginRight: '15px'
                  }}
                />{' '}
                Disclaimer
              </Grid>
              <Grid item xs={12} mb={3} textalign="center">
                <Description>
                  Are you sure to make this change? This can lead to compromised trades and lesser ROIs. It is important to note that different countries and markets have different regulations and practices regarding trading activities. It can have
                  significant impact on trading.
                </Description>
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  marginLeft: '14px',
                  width: mobile360 ? '40%' : '40%'
                }}
              >
                <FreedomButton1
                  btnbackground={theme.palette.icon.iconColor}
                  btncolor={theme.palette.primary.white}
                  variant="contained"
                  borderradius="10px"
                  height={'56px'}
                  onClick={() => submissionHandler()}
                  sx={{ border: 'none', marginBottom: '20px' }}
                >
                  OK
                </FreedomButton1>
              </Box>
            </Grid>
          </InvestmentModel>
        )}
        {open && <FreedomSnackBar />}

        <FreedomPanel mt={1} padding="5px 10px" border={`2px solid ${theme.palette.border.border}`} bgcolor={theme.palette.background.marketcontainerbg}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '41px',
              color: mode === 'light' ? 'black' : 'white'
            }}
          >
            Markets
          </Typography>
          {load ? (
            <FreedomLinearLoader marginleft={'50%'} />
          ) : (
            selectedProduct?.productTypeID === 2 ? <p style={{margin: '0px'}}>{selectedProduct?.description}</p> : (<Grid container>
              {/*To do form group element may require */}
              <Grid item container alignItems="center" mb={1} xs={12} md={10.5} spacing={1}>
                {state?.map((obj, idx) => (
                  <Grid item xs={6} md={4} lg={2} color={theme.palette.primary.onBoardTextColor} key={obj.marketId}>
                    <FreedomSwitch label={obj.market} checked={obj.active} name="markets" disabled={productdashboard} onChange={(e) => handleChange(e, obj.marketId)} />
                  </Grid>
                ))}
              </Grid>
              <Grid item container alignItems="center" xs={12} md={1.5}>
                {!productdashboard && (
                  <Grid container item justifyContent={'end'}>
                    <FreedomButton1
                      btnbackground={theme.palette.button.buttonbgcolor}
                      colorbtn={theme.palette.primary.white}
                      variant="contained"
                      borderradius="10px"
                      // disabled={}
                      width={'110px'}
                      height={'40px'}
                      style={{
                        marginTop: !desktop || !laptop ? '-20px' : ''
                      }}
                      onClick={() => switchTester()}
                      sx={{ border: 'none' }}
                      // disabled={allInactive}
                    >
                      {' '}
                      Save
                    </FreedomButton1>
                  </Grid>
                )}
              </Grid>
            </Grid>)
            
          )}
        </FreedomPanel>
      </>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default TradingMarketsContainer;
