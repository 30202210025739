//API base url
export const getBaseUrl = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_USER_BASE_API_PRE_PROD_URL}api/v1/data/`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BASE_API_PRE_PROD_URL}api/v1/data/`;
  }

  /**:
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BASE_API_PROD_URL}api/v1/data/`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_USER_BASE_API_PROD_URL}api/v1/data/`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae')
  ) {
    return `${process.env.REACT_APP_USER_BASE_API_TEST_URL}api/v1/data/`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BASE_API_TEST_URL}api/v1/data/`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_USER_BASE_API_TEST_URL}api/v1/data/`;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return `${process.env.REACT_APP_USER_BASE_API_DEV_URL}api/v1/data/`;
  }
};

//API for latitute and longitude
export const getUserLocationApiUrl = () => {
  return `${process.env.REACT_APP_LOCATION_INFO}`;
};

export const getBasicUrl = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_USER_BASE_API_PRE_PROD_URL}api/v1/`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BASE_API_PRE_PROD_URL}api/v1/`;
  }

  /**:
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BASE_API_PROD_URL}api/v1/`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_USER_BASE_API_PROD_URL}api/v1/`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae')
  ) {
    return `${process.env.REACT_APP_USER_BASE_API_TEST_URL}api/v1/`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BASE_API_TEST_URL}api/v1/`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_USER_BASE_API_TEST_URL}api/v1/`;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return `${process.env.REACT_APP_USER_BASE_API_DEV_URL}api/v1/`;
  }
};

export const getSiteUrl = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_USER_PRE_PROD_APP_URL}`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_PRE_PROD_APP_URL}`;
  }

  /**:
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_PROD_APP_URL}`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_USER_PROD_APP_URL}`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae')
  ) {
    return `${process.env.REACT_APP_USER_TEST_APP_URL}`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_TEST_APP_URL}`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_USER_TEST_APP_URL}`;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return `${process.env.REACT_APP_USER_DEV_APP_URL}`;
  }
};

//profile image data function
export const getProfileImage = (profileId) => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_USER_BLOB_PRE_PROD_URL}/profilepics/${profileId}.jpg`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BLOB_PRE_PROD_URL}/profilepics/${profileId}.jpg`;
  }

  /**
   * for production
   */

  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BLOB_PROD_URL}/profilepics/${profileId}.jpg`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_USER_BLOB_PROD_URL}/profilepics/${profileId}.jpg`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae')
  ) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/profilepics/${profileId}.jpg`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/profilepics/${profileId}.jpg`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/profilepics/${profileId}.jpg`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/profilepics/${profileId}.jpg`;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return `${process.env.REACT_APP_USER_BLOB_DEV_URL}/profilepics/${profileId}.jpg`;
  }
};

export const getDefaultImage = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_USER_BLOB_PRE_PROD_URL}/profilepics/Default.png`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BLOB_PRE_PROD_URL}/profilepics/Default.png`;
  }
  /**
   * for production
   */

  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BLOB_PROD_URL}/profilepics/Default.png`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_USER_BLOB_PROD_URL}/profilepics/Default.jpg`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae')
  ) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/profilepics/Default.png`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/profilepics/Default.png`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/profilepics/Default.png`;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return `${process.env.REACT_APP_USER_BLOB_DEV_URL}/profilepics/Default.png`;
  }
};

export const getUserPrincipleName = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${getBasicUrl()}User/getuserprincipalname`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${getBasicUrl()}User/getuserprincipalname`;
  }

  /**
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${getBasicUrl()}User/getuserprincipalname`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${getBasicUrl()}User/getuserprincipalname`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae')
  ) {
    // return `${process.env.REACT_APP_BASIC_DEV1_URL}User/getuserprincipalname`;
    return `${getBasicUrl()}User/getuserprincipalname`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae')) {
    // return `${process.env.REACT_APP_BASIC_DEV1_URL}User/getuserprincipalname`;
    return `${getBasicUrl()}User/getuserprincipalname`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${getBasicUrl()}User/getuserprincipalname`;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return `${getBasicUrl()}User/getuserprincipalname`;
  }
};

export const getRowNumber = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return 75;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return 75;
  }

  /**
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return 75;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return 75;
  }

  /**
   * for production
   */
  if (
    window.location.href.includes('https://web.myfreedom.ae') ||
    window.location.href.includes('http://web.myfreedom.ae') ||
    window.location.href.includes('web.myfreedom.ae') ||
    window.location.href.includes('myfreedomprod.azurewebsites.net') ||
    window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') ||
    window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')
  ) {
    return 75;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae')
  ) {
    return 75;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae')) {
    return 75;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return 75;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return 1;
  }
};

export const getCompanyOnBoardForm = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_USER_BLOB_PRE_PROD_URL}/legal/companyonboardingform.pdf`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BLOB_PRE_PROD_URL}/legal/companyonboardingform.pdf`;
  }

  /**
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BLOB_PROD_URL}/legal/companyonboardingform.pdf`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_USER_BLOB_PROD_URL}/legal/companyonboardingform.pdf`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae/')
  ) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/legal/companyonboardingform.pdf`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae/')) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/legal/companyonboardingform.pdf`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/legal/companyonboardingform.pdf`;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return `${process.env.REACT_APP_USER_BLOB_DEV_URL}/legal/companyonboardingform.pdf`;
  }
};

export const getPrivacyPolicyDocument = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_USER_BLOB_PRE_PROD_URL}/legal/privacypolicy.pdf`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BLOB_PRE_PROD_URL}/legal/privacypolicy.pdf`;
  }

  /**
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BLOB_PROD_URL}/legal/privacypolicy.pdf`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_USER_BLOB_PROD_URL}/legal/privacypolicy.pdf`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae/')
  ) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/legal/privacypolicy.pdf`;
  }

  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae/')) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/legal/privacypolicy.pdf`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/legal/privacypolicy.pdf`;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return `${process.env.REACT_APP_USER_BLOB_DEV_URL}/legal/privacypolicy.pdf`;
  }
};

export const getTermsAndConditionsDocument = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_USER_BLOB_PRE_PROD_URL}/legal/termsandconditions.pdf`;
    // return `${process.env.REACT_APP_IMAGE_FREEDOM_PRE_PROD_URL}/terms/Terms_And_Conditions_Version_2.0.pdf`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BLOB_PRE_PROD_URL}/legal/termsandconditions.pdf`;
    // return `${process.env.REACT_APP_IMAGE_FREEDOM_PRE_PROD_URL}/terms/Terms_And_Conditions_Version_2.0.pdf`;
  }

  /**
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BLOB_PROD_URL}/legal/termsandconditions.pdf`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_USER_BLOB_PROD_URL}/legal/termsandconditions.pdf`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae/')
  ) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/legal/termsandconditions.pdf`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae/')) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/legal/termsandconditions.pdf`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/legal/termsandconditions.pdf`;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return `${process.env.REACT_APP_USER_BLOB_DEV_URL}/legal/termsandconditions.pdf`;
  }
};

export const getReleaseNotesDocuments = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_USER_BLOB_PRE_PROD_URL}/release/releasenotesv_${process.env.REACT_APP_RELEASE_NOTES}.pdf`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BLOB_PRE_PROD_URL}/release/releasenotesv_${process.env.REACT_APP_RELEASE_NOTES}.pdf`;
  }
  // https://myfreedomdev.blob.core.windows.net/terms/releasenotesv_1.0.7.pdf
  /**
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_USER_BLOB_PROD_URL}/release/releasenotesv_${process.env.REACT_APP_RELEASE_NOTES}.pdf`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_USER_BLOB_PROD_URL}/release/releasenotesv_${process.env.REACT_APP_RELEASE_NOTES}.pdf`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae/')
  ) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/release/releasenotesv_${process.env.REACT_APP_RELEASE_NOTES}.pdf`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae/')) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/release/releasenotesv_${process.env.REACT_APP_RELEASE_NOTES}.pdf`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_USER_BLOB_TEST_URL}/release/releasenotesv_${process.env.REACT_APP_RELEASE_NOTES}.pdf`;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return `${process.env.REACT_APP_USER_BLOB_DEV_URL}/release/releasenotesv_${process.env.REACT_APP_RELEASE_NOTES}.pdf`;
  }
};

export const getGooglePlaceKey = () => {
  return `${process.env.REACT_APP_MAP_KEY}`;
};

export const getReactInsightKey = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_INSIGHT_API_PREPROD}`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_INSIGHT_API_PREPROD}`;
  }
  /**
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_INSIGHT_API_PROD}`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_INSIGHT_API_PROD}`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae')
  ) {
    return `${process.env.REACT_APP_INSIGHT_API_TEST}`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae')) {
    return `${process.env.REACT_APP_INSIGHT_API_TEST}`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_INSIGHT_API_DEV}`;
  }

  if (window.location.href.includes('https://dev.myfreedom.ae') || window.location.href.includes('http://dev.myfreedom.ae')) {
    return `${process.env.REACT_APP_INSIGHT_API_DEV}`;
  }
};

export const getSupportUsers = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_SUPPORT_USERS_PRODUCTION}`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_SUPPORT_USERS_PRODUCTION}`;
  }
  /**
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_SUPPORT_USERS_PRODUCTION}`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_SUPPORT_USERS_PRODUCTION}`;
  } else if (window.location.href.includes('https://myfreedomprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_SUPPORT_USERS_PRODUCTION}`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae')
  ) {
    return `${process.env.REACT_APP_SUPPORT_USERS}`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae')) {
    return `${process.env.REACT_APP_SUPPORT_USERS}`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_SUPPORT_USERS}`;
  }

  if (window.location.href.includes('https://dev.myfreedom.ae') || window.location.href.includes('http://dev.myfreedom.ae')) {
    return `${process.env.REACT_APP_SUPPORT_USERS}`;
  }
};
