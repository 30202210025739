import React from 'react';
import FreedomPanel from '../../Components/SharedComponent/FreedomPanel/FreedomPanel';
import { Box, Grid } from '@mui/material';
import SummaryComp from '../shared/SummaryComp';
import { PlanVoucherStepWrapper } from '../PlanVoucher1';

const VoucherSecondStep = ({ paymentPayMethod, handleChangeRadioButtonForPaymentMethod, balance, selectedVoucher, form, desc, duration }) => {
  return (
    <Grid item sm={12} lg={12}>
      <PlanVoucherStepWrapper>
        <Box sx={{ maxWidth: '1440px', margin: 'auto' }}>
          <SummaryComp paymentPayMethod={paymentPayMethod} balance={balance} handleChangeRadioButtonForPaymentMethod={handleChangeRadioButtonForPaymentMethod} selectedVoucher={selectedVoucher} form={form} desc={desc} duration={duration} />
        </Box>
      </PlanVoucherStepWrapper>
    </Grid>
  );
};

export default VoucherSecondStep;
