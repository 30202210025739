import React from 'react';
import { styled } from '@mui/system';
import { Button } from '@mui/material';

import { appInsights } from '../../../auth/appInsight';

const FreedomButton = styled(Button)(
  ({
    theme,
    btncolor,
    btnborder,
    width,
    padding,
    lineheight,
    borderradius,
    btnbackground,
    gap,
    fontsize,
    height,
    xlheight,
    xlfontsize,
    fontweight,
    btnboxshadow,
    btnbackground1 = 'none',
    btnboxshadow1,
    btnhovercolor,
    bordercolordisable = '',
    backgroundcolordisable
  }) => ({
    background: btnbackground || 'transparent',
    boxShadow: `0px 4px 14px ${btnboxshadow}`,
    border: btnborder || `2px solid  ${btncolor}`,
    borderRadius: borderradius || '10px',
    gap: gap,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: fontweight || '500',
    fontSize: fontsize || '16px',
    lineHeight: lineheight || '15px',
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
    width: width || '100%',
    height: height || '56px',
    padding: padding || '2px 6px',
    '&:hover': {
      background: btnbackground1 || theme.palette.hoverColor.textFieldHover,
      border: `2px solid  ${theme.palette.border.border}`
    },
    '&:disabled': {
      background: backgroundcolordisable ? backgroundcolordisable : theme.palette.button.disablebtncolor,
      border: bordercolordisable ? `2px solid ${theme.palette.primary.placehorder}` : `none`
    },
    [theme.breakpoints.down('sm')]: {
      padding: '4px',
      fontSize: '12px'
    },
    [theme.breakpoints.up('xll')]: {
      height: xlheight || '56px',
      fontSize: xlfontsize || '18px'
    }
  })
);
const FreedomButton2 = (props) => {
  const { children, ...rest } = props;
  try {
    return (
      <FreedomButton disableElevation={true} disableRipple={true} disableFocusRipple={true} {...rest}>
        {children}
      </FreedomButton>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomButton2;
