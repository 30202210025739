//API base url
export const getMLMBaseUrl = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_MLM_PRE_PROD_BASE_URL}api/v1/data/`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_MLM_PRE_PROD_BASE_URL}api/v1/data/`;
  }

  /**:
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_MLM_BASE_API_PROD_URL}api/v1/data/`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_MLM_BASE_API_PROD_URL}api/v1/data/`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae')
  ) {
    // return `${process.env.REACT_APP_BASE_DEV_URL}`;
    return `${process.env.REACT_APP_MLM_BASE_API_TEST_URL}api/v1/data/`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae')) {
    // return `${process.env.REACT_APP_BASE_DEV_URL}`;
    return `${process.env.REACT_APP_MLM_BASE_API_TEST_URL}api/v1/data/`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_MLM_BASE_API_TEST_URL}api/v1/data/`;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return `${process.env.REACT_APP_MLM_BASE_API_DEV_URL}api/v1/data/`;
  }
};

export const getMLMBasicUrl = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_MLM_PRE_PROD_BASE_URL}api/v1/`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_MLM_PRE_PROD_BASE_URL}api/v1/`;
  }

  /**:
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_MLM_BASE_API_PROD_URL}api/v1/`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_MLM_BASE_API_PROD_URL}api/v1/`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae')
  ) {
    // return `${process.env.REACT_APP_BASE_DEV_URL}`;
    return `${process.env.REACT_APP_MLM_BASE_API_TEST_URL}api/v1/`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae')) {
    // return `${process.env.REACT_APP_BASE_DEV_URL}`;
    return `${process.env.REACT_APP_MLM_BASE_API_TEST_URL}api/v1/`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_MLM_BASE_API_TEST_URL}api/v1/`;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return `${process.env.REACT_APP_MLM_BASE_API_DEV_URL}api/v1/`;
  }
};

export const getMLMSiteUrl = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_MLM_PRE_PROD_APP_URL}`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_MLM_PRE_PROD_APP_URL}`;
  }

  /**:
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_MLM_PROD_APP_URL}`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_MLM_PROD_APP_URL}`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae')
  ) {
    return `${process.env.REACT_APP_MLM_TEST_APP_URL}`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae')) {
    return `${process.env.REACT_APP_MLM_TEST_APP_URL}`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_MLM_TEST_APP_URL}`;
    // return `${process.env.REACT_APP_MLM_DEV_APP_URL}`;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return `${process.env.REACT_APP_MLM_DEV_APP_URL}`;
  }
};

//profile image data function
export const getMLMProfileImage = (profileId) => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_IMAGE_PRE_PROD_URL}/profilepics/${profileId}.jpg`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_IMAGE_FREEDOM_PRE_PROD_URL}/profilepics/${profileId}.jpg`;
  }
  /**
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_MLM_BLOB_PROD_URL}/profilepics/${profileId}.jpg`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_MLM_BLOB_PROD_URL}/profilepics/${profileId}.jpg`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae')
  ) {
    return `${process.env.REACT_APP_IMAGE_TEST_URL}/profilepics/${profileId}.jpg`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae')) {
    return `${process.env.REACT_APP_IMAGE_TEST_URL}/profilepics/${profileId}.jpg`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_IMAGE_TEST_URL}/profilepics/${profileId}.jpg`;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return `${process.env.REACT_APP_IMAGE_DEV_URL}/profilepics/${profileId}.jpg`;
  }
};

export const getMLMDefaultImage = () => {
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae/')) {
    return `${process.env.REACT_APP_IMAGE_STAGE_URL}/profilepics/Default.png`;
  } else if (window.location.href.includes('https://myfreedom.azurewebsites.net') || window.location.href.includes('http://myfreedom.azurewebsites.net')) {
    return `${process.env.REACT_APP_IMAGE_FREEDOM_STAGE_URL}/profilepics/Default.png`;
  }

  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_IMAGE_PRE_PROD_URL}/profilepics/Default.png`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_IMAGE_FREEDOM_PRE_PROD_URL}/profilepics/Default.png`;
  }
  /**
   * for production
   */

  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_MLM_BLOB_PROD_URL}/profilepics/Default.png`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_MLM_BLOB_PROD_URL}/profilepics/Default.jpg`;
  }

  if (window.location.href.includes('https://dev1.myfreedom.ae') || window.location.href.includes('http://dev1.myfreedom.ae/')) {
    return `${process.env.REACT_APP_IMAGE_DEV1_URL}/profilepics/Default.png`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae')
  ) {
    return `${process.env.REACT_APP_IMAGE_TEST_URL}/profilepics/Default.png`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae')) {
    return `${process.env.REACT_APP_IMAGE_TEST_URL}/profilepics/Default.png`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_IMAGE_TEST_URL}/profilepics/Default.png`;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return `${process.env.REACT_APP_IMAGE_DEV_URL}/profilepics/Default.png`;
  }
};

export const getMLMRiskAndManamentDocuments = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_MLM_BLOB_PRE_PROD_URL}/terms/risk_warning_of_freedom.pdf`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_MLM_BLOB_PRE_PROD_URL}/terms/risk_warning_of_freedom.pdf`;
  }

  /**
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_MLM_BLOB_PROD_URL}/terms/risk_warning_of_freedom.pdf`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_MLM_BLOB_PROD_URL}/terms/risk_warning_of_freedom.pdf`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae/')
  ) {
    return `${process.env.REACT_APP_IMAGE_TEST_URL}/terms/risk_warning_of_freedom.pdf`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae/')) {
    return `${process.env.REACT_APP_IMAGE_TEST_URL}/terms/risk_warning_of_freedom.pdf`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_IMAGE_TEST_URL}/terms/risk_warning_of_freedom.pdf`;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return `${process.env.REACT_APP_BLOB_DEV_URL}/terms/risk_warning_of_freedom.pdf`;
  }
};

export const getMLMAffiliateRiskAndManamentDocument = () => {
  /**
   * for pre production
   */
  if (window.location.href.includes('https://myfreedomprod-preprod.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-preprod.azurewebsites.net')) {
    return `${process.env.REACT_APP_MLM_BLOB_PRE_PROD_URL}/terms/affiliatetermsandconditions.pdf`;
  } else if (window.location.href.includes('https://preprod.myfreedom.ae') || window.location.href.includes('http://preprod.myfreedom.ae')) {
    return `${process.env.REACT_APP_MLM_BLOB_PRE_PROD_URL}/terms/affiliatetermsandconditions.pdf`;
  }

  /**
   * for production
   */
  if (window.location.href.includes('https://web.myfreedom.ae') || window.location.href.includes('http://web.myfreedom.ae') || window.location.href.includes('https://app.myfreedom.ae')) {
    return `${process.env.REACT_APP_MLM_BLOB_PROD_URL}/terms/affiliatetermsandconditions.pdf`;
  } else if (window.location.href.includes('https://myfreedomprod-prerelease.azurewebsites.net') || window.location.href.includes('http://myfreedomprod-prerelease.azurewebsites.net')) {
    return `${process.env.REACT_APP_MLM_BLOB_PROD_URL}/terms/affiliatetermsandconditions.pdf`;
  }

  if (
    window.location.href.includes('testwebappfreedom-dzgkckgwcqe5b0e7.northeurope-01.azurewebsites.net') ||
    window.location.href.includes('https://test.myfreedom.ae') ||
    window.location.href.includes('https://freedomv2.azurewebsites.net') ||
    window.location.href.includes('http://test.myfreedom.ae/')
  ) {
    return `${process.env.REACT_APP_IMAGE_TEST_URL}/terms/affiliatetermsandconditions.pdf`;
  }
  if (window.location.href.includes('https://stage.myfreedom.ae') || window.location.href.includes('http://stage.myfreedom.ae/')) {
    return `${process.env.REACT_APP_IMAGE_TEST_URL}/terms/affiliatetermsandconditions.pdf`;
  }

  if (window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000') || window.location.href.includes('localhost:3000')) {
    return `${process.env.REACT_APP_IMAGE_TEST_URL}/terms/affiliatetermsandconditions.pdf`;
  }

  if (
    window.location.href.includes('https://dev.myfreedom.ae') ||
    window.location.href.includes('http://dev.myfreedom.ae') ||
    window.location.href.includes('dev.myfreedom.ae') ||
    window.location.href.includes('myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('https://myfreedom-dev.azurewebsites.net') ||
    window.location.href.includes('http://myfreedom-dev.azurewebsites.net')
  ) {
    return `${process.env.REACT_APP_BLOB_DEV_URL}/terms/affiliatetermsandconditions.pdf`;
  }
};
