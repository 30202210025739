import axios from 'axios';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Box, Grid, Step, StepContent, Stepper, styled, useTheme } from '@mui/material';

import { VoucherUrls } from '../../url/Links';
import useForm from '../../freedomHooks/useForm';
import { getBaseUrl } from '../../utils/userConfig';
import { appInsights } from '../../auth/appInsight';
import useAddress from '../../CustomHooks/useAddress';
import useDataJson from '../../freedomHooks/useDataJson';
import { getUserBalance } from '../../api/apiUrls/apiUrl';
import useStyle, { ColorlibStepIconRoot } from '../../Components/Style';
import StepperLable from './UpgradeProfileSteps/components/StepperLable';
import { accountUpGradeUrl } from '../../api/apiUrls/accountUpgradeApiUrl';
import FreedomSnackBar from '../../Components/SharedComponent/FreedomSnackBar';
import AddressDialog from '../../Settings/MyInformation/Template/AddressDialog';
import UpgradeProfileFirstStep from './UpgradeProfileSteps/UpgradeProfileFirstStep';
import UpgradeProfileThirdStep from './UpgradeProfileSteps/UpgradeProfileThirdStep';
import SuccessTransaction from './UpgradeProfileSteps/components/SuccessTransaction';
import { selectSnackbar, showSnackbar } from '../../features/snackbar/snackbarSlice';
import UpgradeProfileFourthStep from './UpgradeProfileSteps/UpgradeProfileFourthStep';
import UpgradeProfileSecondStep from './UpgradeProfileSteps/UpgradeProfileSecondStep';
import FreedomPanel from '../../Components/SharedComponent/FreedomPanel/FreedomPanel';
import ErrorDialogBox from '../../Components/SharedComponent/ErrorDialogBox/ErrorDialogBox';
import FreedomButton1 from '../../Components/SharedComponent/FreedomButton1/FreedomButton1';
import FreedomButton2 from '../../Components/SharedComponent/FreedomButton2/FreedomButton2';
import { getStripConfirmPaymentUrl, getStripPaymentUrl } from '../../api/apiUrls/walletApiUrl';
import { accountType, orderTypes, paymentAccountType } from '../../FreedomConstants/freedomConstants';
import { updateBottomBarItemType, updateSelectedBottomBarItem } from '../../features/bottomBar/bottomBarSlice';
import InsufficientBalancePopup from '../../Components/SharedComponent/InsufficientBalancePopup/InsufficientBalancePopup';
import { FixedAfterTwoDigit1, RemoveCommaAndDigit, apiErrorResponses, devConsoleLog } from '../../utils/utilityFunctions';

export const UpgradeProfileStepWrapper = styled(Box)(({ theme }) => ({
  padding: '10px',
  height: 'calc(100vh - 360px)',
  border: `2px solid ${theme.palette.border.containerborder}`,
  borderRadius: '10px',
  width: '100%',
  '::-webkit-scrollbar': {
    width: 0, /* Remove scrollbar space */
    background: 'transparent' /* Optional: just make scrollbar invisible */
  },
  overflow: 'auto',
  // [theme.breakpoints.up('xlll')]: {
  //   height: 'calc(100vh - 500px)'
  // }
}));
// const StepperCircleStyle = styled('div')(({ theme, background }) => ({
//   border: `1px solid ${theme.palette.primary.main}`,
//   color: theme.palette.primary.white,
//   background: background || theme.palette.primary.main,
//   width: '40px',
//   height: '40px',
//   borderRadius: '50%',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center'
//   // boxShadow: '0 3px 5px #00000080'
// }));

// export function ColorlibStepIcon(props) {
//   const { active, completed, className } = props;
//   const theme = useTheme();
//   const icons = {
//     1: '1',
//     2: '2',
//     3: '3',
//     4: '4',
//     5: '5'
//   };
//   return (
//     <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
//       {completed ? <CheckIcon width={400} /> : active ? <StepperCircleStyle>{icons[String(props.icon)]}</StepperCircleStyle> : <StepperCircleStyle background={'#000000'}>{icons[String(props.icon)]}</StepperCircleStyle>}
//     </ColorlibStepIconRoot>
//   );
// }
// ColorlibStepIcon.propTypes = {
//   /**
//    * Whether this step is active.
//    * @default false
//    */
//   active: PropTypes.bool,
//   className: PropTypes.string,
//   /**
//    * Mark the step as completed. Is passed to child components.
//    * @default false
//    */
//   completed: PropTypes.bool,
//   /**
//    * The label displayed in the step icon.
//    */
//   icon: PropTypes.node
// };
const steps1 = [
  {
    id: 1,
    label: 'Select Upgrade'
  },
  {
    id: 2,
    label: 'Select Payment Method'
  },
  {
    id: 3,
    label: 'Verification Code'
  },
  {
    id: 4,
    label: 'Payment Summary',
    label1: 'Freedom Bank Details'
  },
  {
    id: 5,
    label: 'Account Upgraded'
  }
];

const siteUrl = getBaseUrl();

//Our Component Start from here
const UpgradeProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { open } = useSelector(selectSnackbar);
  const { t } = useTranslation();

  const classes = useStyle();
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const { jsonPrefix } = useDataJson();
  const confirmOTP = useRef(null);
  //Our Component Local State
  const [loading, setLoading] = useState(false);
  const [systemID, setSystemID] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [IsError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [accountUpGradeArr, setaccountUpGradeArr] = useState([]);
  const [paymentPayMethod, setPaymentPayMethod] = useState(1);
  const [steps, setSteps] = useState(steps1);
  const [cards, setCards] = useState([]);
  const [openAlertMessage, setOpenAlertMessage] = useState('');
  const [opentAlert, setOpenAlert] = useState(false);
  const [hideBackButton, setHideBackButton] = useState(false);
  const [selectedVoucher, setSelectdeVoucher] = useState('');
  const [upgrade, setUpgrade] = useState(true);
  const [balance, setBalance] = useState(0);
  let stepForCreditCard = {
    id: 3,
    label: 'Enter Card Details'
  };
  let stepForInternalAccount = {
    id: 3,
    label: 'Verification Code'
  };

  //selectors to Select State From Redux Store
  // const username = useSelector((state) => state.user.fullName);
  // const email = useSelector((state) => state.user?.userPrincipleName);
  // const transactionFee = useSelector((state) => state.user.transactionFee);
  const { accessToken, accountTypeId, transactionFee, fullname: username, userName: email } = useSelector((state) => state.user.user);

  const { form, handleChange, resetForm, setForm } = useForm({
    cardNumberAlready: '',
    accountNumberAlready: '',
    BillingAddressId: '',
    cardNumber: '',
    cardNumberMasked: '',
    cvc: '',
    cvvmasked: '',
    expMonth: '',
    expYear: '',
    cardExpiryDate: '',
    IsSystemCard: '',
    cardId: '',
    cardHolderName: '',
    accountHolderName: '',
    iban: '',
    bankName: '',
    accountName: '',
    swiftCode: '',
    accountNumber: '',
    cashDepositMethod: '',
    amount: '',
    label: '',
    voucherName: '',
    voucherPrice: '',
    VoucherExpiryDate: '',
    accountTypeID: '',
    orderTypeId: '',
    selected: 'false',
    selectedProduct: {}
  });

  const address = useAddress();
  const [addAddressDialog, setAddAddressDialog] = useState(false);

  const [balanceError, setBalanceError] = useState(false);

  const getAccountbalance = useCallback(
    async (id) => {
      setLoading(true);
      await axios
        .get(getUserBalance(id), {
          headers: { Authorization: 'Bearer ' + accessToken }
        })
        .then((res) => {
          // setBalance(0)
          setBalance(res?.data[0]?.balance);
          devConsoleLog('response from balance', res);
        })
        .catch((error) => {
          devConsoleLog(error);
          appInsights.trackException({ error: new Error(error) });
          dispatch(showSnackbar({ message: `${t(apiErrorResponses(error))}`, severity: 'error' }));
          setLoading(false);
        });
      setLoading(false);
    },
    [balance]
  );
  devConsoleLog('accountUpGradeArr', accountUpGradeArr);
  devConsoleLog('accountUpGradeArr', accountUpGradeArr);
  const getAccountUpGrade = async () => {
    await axios
      .get(accountUpGradeUrl(), {
        headers: { Authorization: 'Bearer ' + accessToken }
      })
      // getAccountUpGradeData(accessToken)
      .then((_res) => {
        devConsoleLog('response from account upgrade', _res.data);

        let Array = [];
        if (_res?.data?.length > 0) {
          _res?.data?.forEach((item, index) => {
            let modifiedText = '';
            if (item?.AccountUpgradeText?.includes('$2.000')) {
              devConsoleLog('contains', item.AccountTypeNumber);
              modifiedText = item?.AccountUpgradeText.replace('$2.000', `$${FixedAfterTwoDigit1(2000, 0)}`);
            } else {
              devConsoleLog('contains else', item.AccountTypeNumber);
              modifiedText = item?.AccountUpgradeText;
            }

            // if (item?.Id > 4) return;
            if ((item?.Id > 1 && item.Id < 5) || (item?.Id > 8 && item?.Id <= 10)) {
              Array.push({
                id: item?.Id,
                VoucherName: item?.Name,
                Price: item?.UpgradePrice,
                accountTypeId: item?.Id,
                selected: false,
                // Description:
                //   "Stay ahead of the competition with early bidding opportunities. Have a direct line of communication with Freedom's management and executives for real-time feedback and advice.",
                Description: modifiedText,
                RoiMin: item?.RoiMin,
                RoiMax: item?.RoiMax,
                RoiValue: item?.RoiValue,
                RiskMin: item?.RiskMin,
                RiskMax: item?.RiskMax,
                RiskValue: item?.RiskValue,
                AccountUpgradeText: item?.AccountUpgradeText,
                Features: item?.Features
              });
            }
          });
          // Array = Array.concat(VipandExclusive);
          setaccountUpGradeArr(Array);
        }
      })
      .catch((error) => {
        devConsoleLog(error);
        appInsights.trackException({ error: new Error(error) });
        dispatch(showSnackbar({ message: `${t(apiErrorResponses(error))}`, severity: 'error' }));
      });
  };
  //useTranslation for changing Language

  useEffect(() => {
    let tempArr = accountUpGradeArr;
    accountUpGradeArr.forEach((item) => {
      if (item?.accountTypeId === accountTypeId) {
        setSelectdeVoucher(item?.id);
        const index = tempArr?.findIndex((object) => {
          return object?.id === item?.id;
        });
        for (let i = 0; i <= index; i++) {
          tempArr[i].selected = true;
        }
        for (let i = index + 1; i <= tempArr.length - 1; i++) {
          tempArr[i].selected = false;
          if ((i === 3 || i === 4) && accountTypeId <= 3) {
            tempArr[i].selected = true;
          }
        }
      } else if (accountTypeId === 1) {
        for (let i = 3; i <= tempArr.length - 1; i++) {
          tempArr[i].selected = true;
        }
      }
    });
    devConsoleLog('tempArr useEffect', tempArr);
    setaccountUpGradeArr(tempArr);
  }, [accountUpGradeArr, accountTypeId]);

  const handleUpgradeProfile = useCallback(
    (accountUpGradeArr, item) => {
      devConsoleLog('item upgrade', item);
      let actualPrice =
        accountTypeId === accountType.standard
          ? item?.Price
          : accountTypeId === accountType.silver
          ? item?.Price - accountUpGradeArr[0]?.Price
          : accountTypeId === accountType.gold
          ? item?.Price - accountUpGradeArr[1]?.Price
          : accountTypeId === accountType.platinum
          ? item?.Price
          : accountTypeId === accountType.exclusive
          ? item?.Price - accountUpGradeArr[3]?.Price
          : '';
      setForm({
        ...form,
        voucherName: item?.VoucherName,
        voucherPrice: actualPrice,
        accountTypeID: item?.accountTypeId,
        selected: true,
        selectedProduct: item
        // orderTypeId:
      });
      // setSelectdeVoucher(item.id);
      devConsoleLog('id here', selectedVoucher, accountUpGradeArr);
      // item.selected = true
      const index = accountUpGradeArr.findIndex((object) => {
        return object.id === item?.id;
      });
      let tempArr = accountUpGradeArr;
      devConsoleLog('tempArr here', accountUpGradeArr);
      // debugger;
      for (let i = 0; i <= tempArr.length - 1; i++) {
        if (accountTypeId === 1 && i !== 3 && i !== 4) {
          tempArr[i].selected = false;
        } else if (accountTypeId === accountType.silver && item?.accountTypeId === 4 && i === 1) {
          tempArr[1].selected = false;
        } else if (accountTypeId === accountType.silver && item?.accountTypeId === 3 && i === 2) {
          tempArr[2].selected = false;
        } else if (accountTypeId === accountType.platinum && item?.accountTypeId === 10 && i === 3) {
          tempArr[3].selected = false;
        } else if (accountTypeId === accountType.platinum && item?.accountTypeId === 9 && i === 4) {
          tempArr[4].selected = false;
        } else {
          tempArr[i].selected = true;
        }
      }
      setaccountUpGradeArr(tempArr);
      devConsoleLog('index here', index);
      devConsoleLog('selected item upgrade', accountUpGradeArr);

      setSelectdeVoucher(item?.id);
      setUpgrade(false);
    },
    [form, selectedVoucher]
  );
  useEffect(() => {
    getAccountbalance(1001);
    getAccountUpGrade();
    // dispatch(updatehideBottombar(true));
  }, []);

  const handleNext = (ev) => {
    setBalanceError(false);
    if (activeStep === steps.length - 1) {
      //this step use for Download Receipt in future
      // dispatch(updatehideBottombar(false));
      // navigate(NewInvestmentUrls.investmentProducts);
      navigate('/');
      window.location.reload();
    } else if (activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 1) {
      if (paymentPayMethod == paymentAccountType.creditCardAccount) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (paymentPayMethod == paymentAccountType.internalAccount) {
        if (balance < +form.voucherPrice + +transactionFee) {
          setOpenDialog(true);
        } else {
          postuserOTPRequests(paymentAccountType.internalAccount);
          setHideBackButton(true);
          // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else if (paymentPayMethod == paymentAccountType.bankAccount) {
        postuserOTPRequests(paymentAccountType.bankAccount);
        setHideBackButton(true);
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 2) {
      if (paymentPayMethod == paymentAccountType.creditCardAccount) {
        handleSubmitStripeRequest(ev);
        setHideBackButton(true);
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (paymentPayMethod == paymentAccountType.internalAccount) {
        confirmOTP.current.confirmOTP();
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (paymentPayMethod == paymentAccountType.bankAccount) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      // dispatch(updatehideBottombar(false));
      dispatch(updateBottomBarItemType('investment'));
      dispatch(updateSelectedBottomBarItem('All Plan'));
      navigate(-1);
      // navigate(NewInvestmentUrls.investmentProducts);
    }
    if (activeStep === 3) {
      // dispatch(updatehideBottombar(true));
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
      navigate(VoucherUrls.upgradeProfile);
    }
    if (activeStep === steps.length - 1) {
      // dispatch(updatehideBottombar(false));
      navigate('/');
      window.location.reload();
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //Function for POST Data for IsSystemCard updattion
  const postuserOTPRequests = (paymentType) => {
    let accountFeeAmount = 0;
    if (paymentType == paymentAccountType.bankAccount) {
      accountFeeAmount = +form.voucherPrice + +transactionFee;
    } else {
      accountFeeAmount = form.voucherPrice;
    }
    setLoading(true);
    let payload = [
      {
        __metadata: { type: 'UserOrderRequests' },
        ...jsonPrefix,
        Id: 0,
        CreatedByUserId: '@UserId',
        ModifiedByUserId: '@UserId',
        UserID: '@UserId',
        OrderTypeId: orderTypes.account,
        PaymentMethodId: paymentType,
        Amount: accountFeeAmount,
        UserOrderLineRequests: [
          {
            Id: 0,
            OrderId: '@OrderId',
            AccountTypeID: form.accountTypeID,
            Amount: accountFeeAmount
          }
        ]
      }
    ];

    const myJSON = JSON.stringify(payload);
    const secondJSON = JSON.stringify(myJSON);
    devConsoleLog('secondJSON', secondJSON);
    axios({
      url: siteUrl,
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + accessToken
      },
      method: 'post',
      data: secondJSON
    })
      .then(function (response) {
        if (response?.data) {
          setSystemID(response?.data);
          if (paymentType == paymentAccountType.bankAccount) {
            setOpenDialog(true);
          }
          if (paymentType == paymentAccountType.internalAccount) {
            dispatch(showSnackbar({ message: 'Code sent to your mobile device or email', severity: 'info' }));
          }
          setLoading(false);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      })
      .catch((error) => {
        appInsights.trackException({ error: new Error(error) });
        dispatch(showSnackbar({ message: `${t(apiErrorResponses(error))}`, severity: 'error' }));
        setErrorMessage(error?.response?.data);
        setIsError(true);
        setLoading(false);
        setActiveStep(0);
      });
  };

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmitStripeRequest = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }
    if (form.voucherPrice === '') {
      return;
    }

    var formattedValue = form.voucherPrice.toLocaleString().split('.')[0];
    formattedValue = formattedValue.toLocaleString().replace(/\D/g, '');
    devConsoleLog('amount to be added', +formattedValue + +transactionFee, RemoveCommaAndDigit(formattedValue));

    let payload = {
      ...jsonPrefix,
      StripePaymentTypeID: 1,
      OrderTypeId: orderTypes.account,
      AccountTypeID: form.accountTypeID,
      Amount: +form.voucherPrice + +transactionFee
    };

    const myJSON = JSON.stringify(payload);
    const secondJSON = JSON.stringify(myJSON);
    devConsoleLog('Updated edit your bank information data posting', secondJSON);

    axios({
      url: getStripPaymentUrl(),
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + accessToken
      },
      method: 'post',
      data: secondJSON
    })
      .then(async (res) => {
        devConsoleLog('response', res);
        let cardExpiry = {};
        let card;
        const { client_secret: clientSecret } = await res.data;

        // Confirm the PaymentIntent using the details collected by the Payment Element
        const payload = await stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams: {
            return_url: 'http://localhost:3000'
          },
          redirect: 'if_required'
        });
        devConsoleLog('payload payment', payload);
        if (payload.paymentIntent && payload.paymentIntent.status === 'succeeded') {
          // setLoading(false);

          await axios
            .post(getStripConfirmPaymentUrl(), JSON.stringify(payload.paymentIntent.id), {
              headers: {
                accept: '*/*',
                'Content-Type': 'application/json;odata.metadata=minimal;odata.streaming=true',
                Authorization: `Bearer ${accessToken}`
              }
            })

            .then(async (res) => {
              devConsoleLog('response12', res);
              card = res?.data?.card;

              if (res?.data?.card?.expMonth >= 1 && res?.data?.card?.expMonth <= 9) {
                card.expiryMonth = res?.data?.card?.expMonth.toString().padStart(2, '0');
                card.expiryYear = res?.data?.card?.expYear.toString().slice(-2);
                card.expiry = `${card.expMonth}/${card.expYear}`;
              } else {
                card.expiryMonth = res?.data?.card?.expMonth?.toString();
                card.expiryYear = res?.data?.card?.expYear.toString().slice(-2);
                card.expiry = `${card.expMonth}/${card.expYear}`;
              }
              devConsoleLog('card check', card);
              setCards(card);
              setLoading(false);
              //  alert("success");

              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            })
            .catch((error) => {
              devConsoleLog('err', error?.response);
              setOpenAlertMessage(error?.response);
              setHideBackButton(false);
              setOpenAlert(true);
              setLoading(false);
            });
        } else {
          if (payload.error) {
            setOpenAlertMessage(payload.error.message);
            setHideBackButton(false);
            setOpenAlert(true);
            setLoading(false);
          } else {
            // setLoading(false)
            // Your customer is redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer is redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response) setErrorMessage(error?.response?.data);
        setIsError(true);
      });
  };

  const hanldeSteperAdd = (newPaymentPayMethod) => {
    let exsitingTempSteps = steps;
    if (steps.length === 5) {
      exsitingTempSteps = [];
      steps.forEach((item, index) => {
        if (index === 2) {
          return 0;
        } else {
          exsitingTempSteps.push(item);
        }
      });
    }
    if (exsitingTempSteps.length !== 5) {
      let tempSteps = [];
      exsitingTempSteps.forEach((item, index) => {
        if (index === 2) {
          if (newPaymentPayMethod == paymentAccountType.creditCardAccount) {
            tempSteps.push(stepForCreditCard, item);
          }
          if (newPaymentPayMethod == paymentAccountType.internalAccount) {
            tempSteps.push(stepForInternalAccount, item);
          }
        } else {
          tempSteps.push(item);
        }
      });
      setSteps(tempSteps);
    }
  };

  const hanldeSteperMinus = (newPaymentPayMethod) => {
    if (steps.length === 5) {
      let tempSteps = [];
      steps.forEach((item, index) => {
        if (index === 2) {
          return 0;
        } else {
          tempSteps.push(item);
        }
      });
      setSteps(tempSteps);
    }
  };
  const handleChangeRadioButtonForPaymentMethod = (event) => {
    if (event.target.value == paymentAccountType.internalAccount) {
      setPaymentPayMethod(paymentAccountType.internalAccount);
      hanldeSteperAdd(paymentAccountType.internalAccount);
    } else if (event.target.value == paymentAccountType.creditCardAccount) {
      setPaymentPayMethod(paymentAccountType.creditCardAccount);
      hanldeSteperAdd(paymentAccountType.creditCardAccount);
    } else if (event.target.value == paymentAccountType.bankAccount) {
      setPaymentPayMethod(paymentAccountType.bankAccount);
      hanldeSteperMinus(paymentAccountType.bankAccount);
    }
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <UpgradeProfileFirstStep loading={loading} data={accountUpGradeArr} callback={handleUpgradeProfile} selectedVoucher={selectedVoucher} />;
      case 1:
        return <UpgradeProfileSecondStep paymentPayMethod={paymentPayMethod} balance={balance} handleChangeRadioButtonForPaymentMethod={handleChangeRadioButtonForPaymentMethod} selectedVoucher={selectedVoucher} form={form} />;
      case 2:
        return (
          <UpgradeProfileThirdStep
            paymentPayMethod={paymentPayMethod}
            openAlertMessage={openAlertMessage}
            opentAlert={opentAlert}
            setOpenAlert={setOpenAlert}
            showSnackbar={showSnackbar}
            setActiveStep={setActiveStep}
            systemID={systemID}
            confirmOTP={confirmOTP}
            openDialog={openDialog}
            setLoading={setLoading}
            setOpenDialog={setOpenDialog}
          />
        );
      case 3:
        return <UpgradeProfileFourthStep paymentPayMethod={paymentPayMethod} form={form} cards={cards} balance={balance} />;
      case 4:
        return (
          <Grid item sm={12} lg={12}>
            <UpgradeProfileStepWrapper>
              <Box sx={{ maxWidth: '1440px', margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <SuccessTransaction paymentPayMethod={paymentPayMethod} />
              </Box>
            </UpgradeProfileStepWrapper>
          </Grid>
        );
      default:
        return 'Unknown step';
    }
  }

  try {
    return (
      <React.Fragment>
        {open && <FreedomSnackBar />}

        {balance < +form.voucherPrice + +transactionFee && openDialog ? <InsufficientBalancePopup openDialog={openDialog} setOpenDialog={setOpenDialog} /> : null}

        {addAddressDialog && <AddressDialog openDialog={addAddressDialog} showMessage={showSnackbar} getAddress={address.fetchData} onClose={() => setAddAddressDialog(false)} />}

        {IsError && <ErrorDialogBox errormessage={errorMessage} setIsError={setIsError} iserror={IsError} />}

        <Box>
          <StepperLable steps={steps} activeStep={activeStep} paymentPayMethod={paymentPayMethod} />
          <Box sx={{ width: '100%' }} className={classes.removeBorder}>
            <Stepper activeStep={activeStep} orientation="vertical" className={classes.stapingCardBalance}>
              {steps.map((step, index) => (
                <Step key={step.id}>
                  <StepContent
                    sx={{
                      margin: '0px!important',
                      padding: '0px!important'
                    }}
                    className={classes.removeBorder}
                  >
                    {getStepContent(index)}
                    <Box sx={{ mb: 2 }}>
                      <Grid container spacing={2} justifyContent="center">
                        {hideBackButton === false && (
                          <Grid item xs={6} md={2}>
                            <FreedomButton2
                              borderradius={'10px'}
                              // disabled={activeStep === 0}
                              onClick={handleBack}
                              sx={{ mt: 2, mr: 1 }}
                              variant="outlined"
                              btncolor={theme.palette.border.border}
                            >
                              {`${t('Back')}`}
                            </FreedomButton2>
                          </Grid>
                        )}
                        <Grid item xs={6} md={2}>
                          <FreedomButton1 
                            onClick={handleNext} 
                            sx={{ mt: 2, mr: 1 }} 
                            btnbackground={theme.palette.button.buttonbgcolor} 
                            borderradius={'10px'} 
                            loading={+loading} 
                            disabled={activeStep === 0 && upgrade} 
                            variant="contained">
                            {activeStep === steps.length - 1 ? `${t('Done')}` : `${t('Next')}`}
                          </FreedomButton1>
                        </Grid>
                      </Grid>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
      </React.Fragment>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default UpgradeProfile;

const VipandExclusive = [
  {
    id: 9,
    accountTypeId: 5,
    VoucherName: 'Exclusive',
    Price: '3000',
    // selected: false,
    Description: 'Elevate your investment experience with Exclusive, a premium product offering tailored strategies and exclusive insights for those seeking exceptional financial growth.'
  },
  {
    id: 10,
    accountTypeId: 6,
    VoucherName: 'VIP',
    Price: '5000',
    // selected: false,
    Description: 'Step into the VIP realm, where sophisticated investment solutions meet personalized service, catering to discerning investors with a taste for exclusive opportunities and unparalleled returns.'
  }
];
