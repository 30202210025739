import React, { useContext, useState } from 'react';
import { styled } from '@mui/system';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import FreedomFollowUpIcon from '../../Components/SharedComponent/FreedomFollowUpIcon/FreedomFollowUpIcon';
import FreedomButton1 from '../../Components/SharedComponent/FreedomButton1/FreedomButton1';
import { CheckCircleOutline } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { ColorModeContext } from '../../store';
import { FixedAfterTwoDigit1, devConsoleLog } from '../../utils/utilityFunctions';
import { images, upgradeAccount, voucherSvg } from '../../url/SvgLinks';
import FreedomTypographyText from '../../Components/SharedComponent/FreedomTypographyText/FreedomTypographyText';
import EmptyPopup from '../../Components/SharedComponent/ProductsPopup/ProductsPopup';
import UpgradeAccountCircle from '../../Components/SharedComponent/UpgradeAccountCircle/UpgradeAccountCircle';
import GuageMeter from '../../Components/SharedComponent/GuageMeter/GuageMeter';
import { Para } from '../../Components/SharedComponent/WelcomeScreenElement/WelcomeScreenElement';

const silver = images.silver;
const platinum = images.platinum;
const gold = images.gold;
const exclusive = upgradeAccount.ExclusiveAccountTypeIcon;
const vip = upgradeAccount.VipAccountTypeIcon;
const SilverDark = upgradeAccount.SilverDark;
const SilverLight = upgradeAccount.SilverLight;
const GoldLight = upgradeAccount.GoldLight;
const GoldDark = upgradeAccount.GoldDark;
const PlatinumDark = upgradeAccount.PlatinumDark;
const PlatinumLight = upgradeAccount.PlatinumLight;
const ExclusiveDarkBg = upgradeAccount.ExclusiveDarkBg;
const ExclusiveLightBg = upgradeAccount.ExclusiveLightBg;
const VipDarkBg = upgradeAccount.VipDarkBg;
const VipLightBg = upgradeAccount.VipLightBg;

const VoucherWrapper = styled(Box)(({ theme, mode, title, ischecked, hover, maxheight }) => ({
  width: '352px',
  height: '350px',
  position: 'relative',
  maxHeight: maxheight || '',
  padding: '8px 16px',
  border:
    ischecked === true
      ? title === 'Silver'
        ? mode === 'light'
          ? '2px solid #505050'
          : '2px solid #B1B1B1'
        : title === 'Gold'
        ? mode === 'light'
          ? '2px solid #ECC44F'
          : '2px solid #CEA631'
        : title === 'Platinum'
        ? mode === 'light'
          ? '2px solid #C111FF'
          : '2px solid #C111FF'
        : title === 'Exclusive'
        ? mode === 'light'
          ? `2px solid ${theme.palette.primary.exclusivebgbutton}`
          : `2px solid ${theme.palette.primary.exclusivebgbutton}`
        : title === 'VIP'
        ? mode === 'light'
          ? `2px solid ${theme.palette.primary.vipbgbutton}`
          : `2px solid ${theme.palette.primary.vipbgbutton}`
        : ''
      : '',
  backgroundImage:
    title === 'Silver'
      ? mode === 'dark'
        ? `url(${SilverDark})`
        : `url(${SilverLight})`
      : title === 'Gold'
      ? mode === 'dark'
        ? `url(${GoldDark})`
        : `url(${GoldLight})`
      : title === 'Platinum'
      ? mode === 'dark'
        ? `url(${PlatinumDark})`
        : `url(${PlatinumLight})`
      : title === 'Exclusive'
      ? mode === 'dark'
        ? `url(${ExclusiveDarkBg})`
        : `url(${ExclusiveLightBg})`
      : title === 'VIP'
      ? mode === 'dark'
        ? `url(${VipDarkBg})`
        : `url(${VipLightBg})`
      : '',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '15px',
  [theme.breakpoints.down('sm')]: {
    width: '320px'
  }
}));

const VoucherCardTitle = styled('h3')(({ theme, ischecked, mode, hover, selected }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '35px',
  marginBottom: 0,
  color: selected && !ischecked ? theme.palette.primary.disableplaceholder : theme.palette.primary.onBoardTextColor
  // [theme.breakpoints.only('lg')]: {
  //   fontSize: '18px'
  // },
  // [theme.breakpoints.down('md')]: {
  //   fontSize: '14px'
  // }
}));

const VoucherCardPrice = styled('h3')(({ theme, ischecked, mode, hover, selected }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '36px',
  color: selected && !ischecked ? theme.palette.primary.disableplaceholder : theme.palette.primary.onBoardTextColor
  // [theme.breakpoints.down('sm')]: {
  //   fontWeight: '600',
  //   fontSize: '32px',
  //   lineHeight: '48px'
  // }
}));
const ExcVipStatusText = styled('p')(({ theme }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '6px',
  lineHeight: '9px',
  textAlign: 'center',
  margin: '0px',
  color: theme.palette.primary.onBoardTextColor
}));
const ExcVipStatus = styled('div')(({ theme }) => ({
  width: '40px',
  height: '15px',
  padding: '6px',
  borderRadius: '3px',
  backgroundColor: '#C111FF',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'

  // [theme.breakpoints.down('sm')]: {
  //   fontWeight: '600',
  //   fontSize: '32px',
  //   lineHeight: '48px'
  // }
}));

const CardBorder = styled('div')(({ theme, ischecked, title, mode }) => ({
  border:
    ischecked === true
      ? title === 'Silver'
        ? mode === 'light'
          ? '2px solid #505050'
          : '2px solid #B1B1B1'
        : title === 'Gold'
        ? mode === 'light'
          ? '2px solid #ECC44F'
          : '2px solid #CEA631'
        : title === 'Platinum'
        ? mode === 'light'
          ? '2px solid #C111FF'
          : '2px solid #C111FF'
        : title === 'Exclusive'
        ? mode === 'light'
          ? `2px solid ${theme.palette.primary.exclusivebgbutton}`
          : `2px solid ${theme.palette.primary.exclusivebgbutton}`
        : title === 'VIP'
        ? mode === 'light'
          ? `2px solid ${theme.palette.primary.vipbgbutton}`
          : `2px solid ${theme.palette.primary.vipbgbutton}`
        : ''
      : '',
  borderRadius: '18px'
}));
const VoucherCardDesc = styled('h5')(({ theme, ischecked, mode, hover, selected }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '21px',
  textAlign: 'left',
  color: selected && !ischecked ? theme.palette.primary.disableplaceholder : theme.palette.primary.onBoardTextColor,
  //   : mode === "light"
  //   ? ischecked
  //     ? // || hover === true
  //       theme.palette.background.default
  //     : theme.palette.primary.onBoardTextColor
  //   : theme.palette.primary.onBoardTextColor,
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '18px'
  }
}));

const VoucherCard = (props) => {
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);

  const { disable, item, title, price, description, ischecked, id, clickOnCard, upgrdeDes = true, CardTitle, CardPrice, CardPriceMonth, maxheight, product, productvalue, roi, risk, features } = props;
  const theme = useTheme();
  console.log('itemitem', item);
  const [hover, setHover] = useState(false);
  const [productClicked, setProductClicked] = useState(false);
  const accountTypeId = useSelector((state) => state.user.accountTypeId);
  const products = useSelector((state) => state.investmentProducts.products);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mobile360 = useMediaQuery(theme.breakpoints.only('sm'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));

  const hanleViewClick = (item) => {
    setProductClicked(true);
    console.log('product data', item);
  };

  // try {
  return (
    <>
      <Box>{productClicked && <EmptyPopup item={item} openDialog={productClicked} setOpenDialog={setProductClicked} />}</Box>
      {/* <CardBorder ischecked={ischecked} title={title} mode={mode}> */}
      <VoucherWrapper
        title={title}
        mode={mode}
        ischecked={ischecked}
        hover={hover}
        maxheight={maxheight}
        // border={theme.palette.border.borderColor}
        // ischecked={ischecked}
        // sx={{ padding: mobile360 ? '5px' : '24px' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ marginRight: '10px' }}>
                  <FreedomFollowUpIcon
                    backgroundcolor={
                      title === 'Silver'
                        ? theme.palette.background.default
                        : title === 'Gold'
                        ? theme.palette.background.default
                        : title === 'Platinum'
                        ? theme.palette.background.default
                        : title === 'Exclusive'
                        ? theme.palette.background.default
                        : title === 'VIP'
                        ? theme.palette.background.default
                        : ''
                    }
                    // padding={'6px'}
                    // backgroundhovercolor={theme.palette.primary.white}
                    disabled
                    bordercolor={theme.palette.border.upgradeAccountIcon}
                    iconimg={item?.accountTypeId === 2 ? silver : item?.accountTypeId === 3 ? gold : item?.accountTypeId === 4 ? platinum : item?.accountTypeId === 9 ? exclusive : item?.accountTypeId === 10 ? vip : ''}
                    borderradius="12px"
                    width={'56px'}
                    height={'56px'}
                    imageheight="32px"
                    imagewidth="32px"
                    responsiveheight={'56px'}
                    responsivewidth={'56px'}
                    // xllheight={'56px'}
                    // xllwidth={'56px'}
                    responsiveborderrad={'12px'}
                  />
                </Box>
                {title == 'Exclusive' || title == 'VIP' ? (
                  <Box>
                    <Box>
                      <ExcVipStatus>
                        <ExcVipStatusText>Platinum</ExcVipStatusText>
                      </ExcVipStatus>
                    </Box>
                    <Box>
                      <VoucherCardTitle ischecked={ischecked} mode={mode} hover={hover} selected={item?.selected}>
                        {title}
                      </VoucherCardTitle>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <VoucherCardTitle ischecked={ischecked} mode={mode} hover={hover} selected={item?.selected}>
                      {title}
                    </VoucherCardTitle>
                  </Box>
                )}
              </Box>
              {/* <FreedomTypographyText style={{ cursor: 'pointer' }} fontsize={'10px'} xllfontsize={'16px'} lineheight={'normal'} xllineheight={'normal'} onClick={() => hanleViewClick(item)}>
                View Details
              </FreedomTypographyText> */}
            </Box>
            <Grid container display={'flex'} flexDirection={'column'} justifyContent={'space-between'} alignItems={'end'}>
              <Grid container item mt={CardPrice || 2}>
                <Grid item>
                  <FreedomTypographyText textalign={'left'} fontweight={'400'} fontsize={'10px'} xllfontsize={'16px'} lineheight={'normal'} xllineheight={'normal'}>
                    Account Upgrade Fee
                  </FreedomTypographyText>
                  <VoucherCardPrice ischecked={ischecked} mode={mode} hover={hover} selected={item?.selected}>
                    {/* ${item?.Price} */}${FixedAfterTwoDigit1(price)}
                  </VoucherCardPrice>
                </Grid>
                {title == 'Exclusive' || title == 'VIP' ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <VoucherCardDesc ischecked={ischecked} mode={mode} hover={hover} selected={item?.selected}>
                        {features}
                      </VoucherCardDesc>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid container justifyContent={'center'}>
                      <Grid item container xs={5} justifyContent={'center'}>
                        <Grid item>
                          <FreedomTypographyText textalign={'left'} fontweight={'600'} fontsize={'20px'} xllfontsize={'20px'} lineheight={'normal'} xllineheight={'normal'} margin="0 0 0 20px">
                            ROI <span style={{ fontSize: '8px', fontWeight: '400' }}>(upto)</span>
                          </FreedomTypographyText>
                        </Grid>
                      </Grid>
                      <Grid item container xs={5} justifyContent={'center'} gap={0.8}>
                        <Grid item>
                          <FreedomTypographyText textalign={'left'} fontweight={'600'} fontsize={'20px'} xllfontsize={'20px'} lineheight={'normal'} xllineheight={'normal'}>
                            Risk <span style={{ fontSize: '8px', fontWeight: '400' }}>(from)</span>
                          </FreedomTypographyText>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item container justifyContent={'center'} sx={{ position: 'relative' }}>
                      <UpgradeAccountCircle pathtitle={item?.VoucherName} />

                      <React.Fragment>
                        <Box
                          sx={{
                            position: 'absolute',
                            left: mobile ? '15%' : '18%',
                            // right: lg ? '58%' : xl ? '58%' : '59%',
                            top: '13%'
                            // top: mobile ? '10%' : mobile360 ? '10%' : '24%'
                          }}
                        >
                          {' '}
                          <GuageMeter
                            min={item?.RoiMin}
                            max={item?.RoiMax}
                            value={item?.RoiValue}
                            // innervaluefontsize={'10px'}
                            // innervalueHeight={'16px'}
                            height={85}
                            width={85}
                            // height={mobile ? 80 : mobile360 ? 80 : 100}
                            // width={mobile ? 80 : mobile360 ? 80 : 100}
                            minspacePercentage={0.25}
                            maxspacePercentage={0.85}
                          />
                        </Box>
                        <Box
                          sx={{
                            position: 'absolute',
                            right: mobile ? '15%' : '18%',
                            top: '13%'

                            // position: 'absolute',
                            // left: lg ? '58%' : xl ? '58%' : '59%',
                            // top: mobile ? '10%' : mobile360 ? '10%' : '24%'
                          }}
                        >
                          <GuageMeter min={item?.RiskMin} max={item?.RiskMax} value={item?.RiskValue} innervaluefontsize={'10px'} innervalueHeight={'16px'} height={85} width={85} minspacePercentage={0.25} maxspacePercentage={0.85} />
                        </Box>
                      </React.Fragment>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box>
            {ischecked ? (
              <Grid item xs={12} mt={1}>
                <FreedomButton1
                  btnbackground={
                    title === 'Silver'
                      ? theme.palette.button.vouchersilverbtncolor
                      : // "#505050"
                      //  'rgba(115, 115, 115, 0.3)'
                      title === 'Platinum'
                      ? theme.palette.border.platinumborder
                      : title === 'Gold'
                      ? theme.palette.primary.primaryYellowGold
                      : title == 'Exclusive'
                      ? theme.palette.primary.exclusivebgbutton
                      : title == 'VIP'
                      ? theme.palette.primary.vipbgbutton
                      : theme.palette.background.default
                  }
                  btndisabledbg={
                    title === 'Silver'
                      ? theme.palette.button.vouchersilverbtncolor
                      : // "rgba(115, 115, 115, 0.3)"
                      title === 'Platinum'
                      ? theme.palette.border.platinumborder
                      : title === 'Gold'
                      ? theme.palette.icon.iconColor
                      : title == 'Exclusive'
                      ? theme.palette.primary.exclusivebgbutton
                      : title == 'VIP'
                      ? theme.palette.primary.vipbgbutton
                      : theme.palette.background.default
                  }
                  btnborder={
                    title === 'Silver'
                      ? 'none'
                      : // 'rgba(115, 115, 115, 0.9)'
                      title === 'Gold'
                      ? 'none'
                      : title === 'Platinum'
                      ? 'none'
                      : 'none'
                  }
                  btncolor={mode === 'dark' ? theme.palette.primary.onBoardTextColor : theme.palette.background.default}
                  btndisabledcolor={mode === 'dark' ? theme.palette.primary.onBoardTextColor : theme.palette.background.default}
                  btnhovercolor={
                    title === 'Platinum'
                      ? theme.palette.border.platinumborder
                      : title === 'Gold'
                      ? theme.palette.primary.primaryYellowGold
                      : title === 'Silver'
                      ? theme.palette.button.vouchersilverbtncolor
                      : title == 'Exclusive'
                      ? theme.palette.primary.exclusivebgbutton
                      : title == 'VIP'
                      ? theme.palette.primary.vipbgbutton
                      : theme.palette.primary.default
                  }
                  texthovercolor={mode === 'dark' ? theme.palette.primary.onBoardTextColor : theme.palette.background.default}
                  startIcon={<CheckCircleOutline />}
                  padding={'12px 16px'}
                  lineheight={'24px'}
                  height={'48px'}
                  xlheight={'48px'}
                  borderradius={'6px'}
                  disabled={item?.selected}
                  variant="contained"
                  onClick={clickOnCard}
                  onMouseOver={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  {item?.accountTypeId === accountTypeId ? 'Subscribed' : 'Selected'}
                </FreedomButton1>
              </Grid>
            ) : (
              <Grid item xs={12} mt={1}>
                <FreedomButton1
                  btnbackground={theme.palette.background.default}
                  btncolor={theme.palette.primary.onBoardTextColor}
                  btnhovercolor={
                    title === 'Platinum'
                      ? theme.palette.border.platinumborder
                      : title === 'Gold'
                      ? theme.palette.primary.primaryYellowGold
                      : title === 'Silver'
                      ? theme.palette.button.vouchersilverbtncolor
                      : title == 'Exclusive'
                      ? theme.palette.primary.exclusivebgbutton
                      : title == 'VIP'
                      ? theme.palette.primary.vipbgbutton
                      : theme.palette.primary.default
                  }
                  texthovercolor={mode === 'dark' ? theme.palette.primary.onBoardTextColor : theme.palette.background.default}
                  btndisabledbg={'none'}
                  // btndisabledcolor={mode === "light" ? "#A9A9A9" : "#666666"}
                  btndisabledcolor={theme.palette.primary.disableplaceholder1}
                  //   startIcon={<CheckCircleOutline />}
                  btnborder={
                    item?.selected
                      ? theme.palette.primary.disableplaceholder1
                      : title === 'Silver'
                      ? theme.palette.primary.onBoardTextColor
                      : title === 'Gold'
                      ? theme.palette.primary.grpahDropdown
                      : title === 'Platinum'
                      ? theme.palette.border.platinumborder
                      : title == 'Exclusive'
                      ? theme.palette.primary.exclusivebgbutton
                      : title == 'VIP'
                      ? theme.palette.primary.vipbgbutton
                      : theme.palette.primary.onBoardTextColor
                  }
                  padding={'12px 16px'}
                  lineheight={'24px'}
                  height={'48px'}
                  xlheight={'48px'}
                  borderradius={'6px'}
                  disabled={item?.selected}
                  variant="contained"
                  onClick={clickOnCard}
                  onMouseOver={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  sx={{
                    '&:hover': {
                      border: 'none'
                    }
                  }}
                >
                  {item?.selected ? 'Not Available' : 'Select'}
                </FreedomButton1>
              </Grid>
            )}
          </Box>
        </Box>
      </VoucherWrapper>
      {/* </CardBorder> */}
    </>
  );
  // } catch (error) {
  //   appInsights.trackException({ error: new Error(error) });
  // }
};

export default VoucherCard;
