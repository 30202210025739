import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { images } from '../../../url/SvgLinks';
import { appInsights } from '../../../auth/appInsight';
import { LineWave } from 'react-loader-spinner';
import { height } from '@mui/system';

const logo = images.logo;

const useStyles = makeStyles((theme) => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  freedomGif: {
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  centerSmallScreen: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50%'
  }
}));
const FreedomLoader = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className="flex">
      <div id="fuse-splash-screen">
        <div className="center">
          {/* <div className="logo">
						<img className={classes.freedomGif} src={FreedomIcon} alt="logo" />
					</div> */}
          <div className="logo">
            <img className="w-128" src={logo} alt="logo" />
          </div>
          <div className="circonf-wrapper">
            <div className="circonf circonf-5" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreedomLoader;

export const Loader = () => {
  const classes = useStyles();

  const theme = useTheme();
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  if (isSmallScreen) {
    try {
      return (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height:'100%'
          }}
        >
          <LineWave height="100" width="100" color={theme.palette.primary.main} ariaLabel="line-wave" wrapperStyle={{}} wrapperClass="" visible={true} firstLineColor="" middleLineColor="" lastLineColor="" />
          <Box>{t('Loading')}...</Box>
        </Box>
      );
    } catch (error) {
      appInsights.trackException({ error: new Error(error) });
    }
  } else {
    try {
      return (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%'
          }}
        >
          {/* <div className={classes.center}> */}
          {/* <div className="circonf-wrapper">
              <div className="circonf circonf-5" />
            </div> */}
          <LineWave height="100" width="100" color={theme.palette.primary.main} ariaLabel="line-wave" wrapperStyle={{}} wrapperClass="" visible={true} firstLineColor="" middleLineColor="" lastLineColor="" />
          <Box>{t('Loading')}...</Box>
          {/* </div>  */}
          {/* <div className={classes.center}>
					<div className="logo">
						<img width='100px' height='100px' className="w-128 zoom-in-zoom-out" src={FreedomIcon} alt="logo" />
					</div>
				</div>
				<FreedomDialogTypography fontsize='12px' lineheight='18px'>{t('Loading')}</FreedomDialogTypography> */}
        </Box>
      );
    } catch (error) {
      appInsights.trackException({ error: new Error(error) });
    }
  }
};
