import axios from 'axios';
import PropTypes from 'prop-types';
import { styled } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Box, Divider, Grid, Step, StepContent, StepLabel, Stepper, useMediaQuery, useTheme } from '@mui/material';

import { ColorModeContext } from '../../store';
import { getSiteUrl } from '../../utils/userConfig';
import TopNavBar1 from '../../Components/TopNavBar1';
import useDataJson from '../../freedomHooks/useDataJson';
import { VoucherWrapper } from '../../Voucher/PlanVoucher1';
import { orderTypes } from '../../FreedomConstants/freedomConstants';
import useStyle, { ColorlibStepIcon, ColorlibStepIconRoot } from '../../Components/Style';
import FreedomAlert from '../../Components/SharedComponent/FreedomAlert/FreedomAlert';
import FreedomWrapper from '../../Components/SharedComponent/FreedomWrapper/FreedomWrapper';
import FreedomButton1 from '../../Components/SharedComponent/FreedomButton1/FreedomButton1';
import { formatExpirationDate } from '../../Wallet/ManagePaymentOptions/utils/utilFunctions';
import { getStripConfirmPaymentUrl, getStripPaymentUrl } from '../../api/apiUrls/walletApiUrl';
import { Para } from '../../Components/SharedComponent/WelcomeScreenElement/WelcomeScreenElement';
import FreedomCreditCard from '../../Components/SharedComponent/FreedomCreditCard/FreedomCreditCard';
import FreedomWrapperRoot from '../../Components/SharedComponent/FreedomWrapperRoot/FreedomWrapperRoot';
import { FreedomTypography } from '../../Components/SharedComponent/FreedomTypography/FreedomTypography';
import FreedomSuccessImage from '../../Components/SharedComponent/FreedomSuccessImage/FreedomSuccessImage';
import FreedomColorDivider from '../../Components/SharedComponent/FreedomColorDivider/FreedomColorDivider';
import FreedomTypographyText from '../../Components/SharedComponent/FreedomTypographyText/FreedomTypographyText';
import { FixedAfterTwoDigit1, apiErrorResponses, capitalizeFullName, currentDate, devConsoleLog } from '../../utils/utilityFunctions';
import { FreedomDialogTypography } from '../../Components/SharedComponent/FreedomDialogTypography/FreedomDialogTypography';
import { selectSnackbar, showSnackbar } from '../../features/snackbar/snackbarSlice';
import FreedomSnackBar from '../../Components/SharedComponent/FreedomSnackBar';
import Layout from '../../UpgradedInvestment/Layouts/Layout';

const StepWrapper = styled(Box)(({ theme }) => ({
  padding: '10px',
  height: 'calc(100vh - 350px)',
  border: `2px solid ${theme.palette.border.containerborder}`,
  borderRadius: '10px',
  '::-webkit-scrollbar': {
    width: 0 /* Remove scrollbar space */,
    background: 'transparent' /* Optional: just make scrollbar invisible */
  },
  overflow: 'auto',
  [theme.breakpoints.up('xlll')]: {
    height: 'calc(100vh - 500px)'
  }
}));

const FeeWrapper = styled('div')(({ theme, bgcolor, padding, height }) => ({
  border: `2px solid ${theme.palette.border.containerborder}`,
  padding: padding || '5px',
  borderRadius: '10px',
  backgroundColor: bgcolor || '',
  height: height || ''
}));

const Title = styled('div')(({ theme, fontWeight, fontSize, lineHeight, textAlign, color }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: fontWeight || '400',
  fontSize: fontSize || '13px',
  lineHeight: lineHeight || '18px',
  color: color || theme.palette.primary,
  whiteSpace: 'nowrap',
  width: '70%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: textAlign || 'center'
}));

// function ColorlibStepIcon(props) {
//   const { active, completed, className } = props;

//   const icons = {
//     1: '1',
//     2: '2',
//     3: '3',
//     4: '4',
//     5: '5'
//   };

//   return (
//     <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
//       {icons[String(props.icon)]}
//     </ColorlibStepIconRoot>
//   );
// }

// ColorlibStepIcon.propTypes = {
//   /**
//    * Whether this step is active.
//    * @default false
//    */
//   active: PropTypes.bool,
//   className: PropTypes.string,
//   /**
//    * Mark the step as completed. Is passed to child components.
//    * @default false
//    */
//   completed: PropTypes.bool,
//   /**
//    * The label displayed in the step icon.
//    */
//   icon: PropTypes.node
// };

const steps = [
  {
    id: 2,
    label: 'Payment Details'
  },
  {
    id: 3,
    label: 'Transaction Details'
  },
  {
    id: 4,
    label: 'Profile Pending Approval'
  }
];

function BusinessOnboardFee() {
  const theme = useTheme();
  const classes = useStyle();
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const { jsonPrefix } = useDataJson();
  const dispatch = useDispatch();
  const { open } = useSelector(selectSnackbar);

  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [openAlertMessage, setOpenAlertMessage] = useState('');
  const [opentAlert, setOpenAlert] = useState(false);

  const companyDocumentVerificationFee = 95;
  const mobile360 = useMediaQuery(theme.breakpoints.down('sm'));
  const ismdScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const [errorMessage, setErrorMessage] = useState('');

  const { accessToken, transactionFee, userName: email } = useSelector((state) => state.user.user);

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmitStripeRequest = async (event) => {
    // We don't want to let default form submission happen here,

    // which would refresh the page.

    event.preventDefault();
    if (!stripe) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setLoading(true); // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }
    let payload = {
      ...jsonPrefix,
      StripePaymentTypeID: 1,
      OrderTypeId: orderTypes.companyDocumentVerivicationFee,
      Amount: companyDocumentVerificationFee + +transactionFee
    };

    const myJSON = JSON.stringify(payload);
    const secondJSON = JSON.stringify(myJSON);
    devConsoleLog('Updated edit your bank information data posting', secondJSON);

    axios({
      url: getStripPaymentUrl(),
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + accessToken
      },
      method: 'post',
      data: secondJSON
    })
      .then(async (res) => {
        let card = {};
        devConsoleLog('response in payment', res);
        const { client_secret: clientSecret } = await res.data; // Confirm the PaymentIntent using the details collected by the Payment Element
        const payload = await stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams: {
            // return_url: "localhost:3000",
            return_url: getSiteUrl()
          },
          redirect: 'if_required'
        });

        devConsoleLog('payload payment', payload);
        if (payload.error) {
          devConsoleLog('payment error', payload);
          setOpenAlertMessage(payload?.error?.message);
          setOpenAlert(true);
          setLoading(false);
          devConsoleLog('err1', payload.error); // This point is only reached if there's an immediate error when // confirming the payment. Show the error to your customer (for example, payment details incomplete) // handleError(payload.error);
        } else {
          // setLoading(false)
          // Your customer is redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer is redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }

        if (payload.paymentIntent.status === 'succeeded') {
          // setLoading(false);

          await axios
            .post(
              getStripConfirmPaymentUrl(),
              // '"pi_3N3IwRCu1NpMTNPF0uMUjy13"',
              JSON.stringify(payload.paymentIntent.id),
              {
                headers: {
                  accept: '*/*',
                  'Content-Type': 'application/json;odata.metadata=minimal;odata.streaming=true',
                  Authorization: 'Bearer ' + accessToken
                }
              }
            )
            .then(async (res) => {
              devConsoleLog('response12', res);
              card = res?.data?.card;

              if (res?.data?.card?.expMonth >= 1 && res?.data?.expMonth <= 9) {
                card.expiryMonth = res?.data?.card?.expMonth.toString().padStart(2, '0');
                card.expiryYear = res?.data?.card?.expYear.toString().slice(-2);
                card.expiry = `${card.expMonth}/${card.expYear}`;
              } else {
                card.expiryMonth = res?.data?.card?.expMonth?.toString();
                card.expiryYear = res?.data?.card?.expYear.toString().slice(-2);
                card.expiry = `${card.expMonth}/${card.expYear}`;
              }
              devConsoleLog('card check', card);
              setCards(card);
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
              setLoading(false);
            })
            .catch((error) => {
              devConsoleLog('error occured here', error?.response);
              setOpenAlertMessage(error?.response);
              setOpenAlert(true);
              setLoading(false);
            });
        }
      })

      .catch((error) => {
        devConsoleLog('err', error?.response);
        setLoading(false);
        dispatch(showSnackbar({ message: apiErrorResponses(error), severity: 'error' }));
      }); // // Create the PaymentIntent and obtain clientSecret // const res = await fetch("https://api-dev.myfreedom.ae/api/v1/payments/stripepayment", { //     headers: {Authentication: `Bearer ${accessToken}`}, //     method: "POST", // });
  };

  const handleNext = (ev) => {
    if (activeStep === steps.length - 1) {
      window.location.href = '/';
    }
    if (activeStep === 0) {
      handleSubmitStripeRequest(ev);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <StepWrapper>
            <Grid spacing={2} mt={2} container justifyContent={'center'}>
              <Grid item xs={12} md={5} lg={4} container>
                <Grid item xs={12} md={12}>
                  <FeeWrapper sx={{ mb: 1 }} padding={'17px 12px'} height="100%">
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <FreedomTypographyText
                        textalign="left"
                        fontfamily="Poppins"
                        fontweight={600}
                        lineheight={'25px'}
                        fontsize={mobile360 ? '12px' : ismdScreen ? '14px' : '16px'}
                        textshadow={`2px 2px 4px ${theme.palette.background.default}`}
                        color={theme.palette.primary.onBoardTextColor}
                      >
                        Company Setup Fee{' '}
                      </FreedomTypographyText>

                      <FreedomTypographyText fontweight={600} lineheight={'25px'} fontsize={mobile360 ? '12px' : ismdScreen ? '14px' : '16px'} color={theme.palette.primary.disableplaceholder}>
                        ${FixedAfterTwoDigit1(+companyDocumentVerificationFee)}
                      </FreedomTypographyText>
                    </Box>
                    <Divider
                      sx={{
                        marginTop: '10px',
                        backgroundColor: theme.palette.border.containerborder
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '5px'
                      }}
                    >
                      <FreedomTypographyText
                        textalign="left"
                        fontfamily="Poppins"
                        fontweight={600}
                        lineheight={'25px'}
                        textshadow={`2px 2px 4px ${theme.palette.background.default}`}
                        fontsize={mobile360 ? '12px' : ismdScreen ? '14px' : '16px'}
                        color={theme.palette.primary.onBoardTextColor}
                      >
                        Freedom Transaction Fee{' '}
                      </FreedomTypographyText>
                      <FreedomTypographyText fontweight={600} lineheight={'25px'} color={theme.palette.primary.disableplaceholder} fontsize={mobile360 ? '12px' : ismdScreen ? '14px' : '16px'}>
                        ${FixedAfterTwoDigit1(+transactionFee)}
                      </FreedomTypographyText>
                    </Box>
                    <FreedomTypographyText fontsize="10px" color={theme.palette.primary.disableplaceholder} textalign={'left'}>
                      *$2.00 Freedom Transaction Fee is added in your entered amount.
                    </FreedomTypographyText>

                    <Divider
                      sx={{
                        margin: '10px 0',
                        backgroundColor: theme.palette.border.containerborder
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <FreedomTypographyText fontsize={mobile360 ? '12px' : ismdScreen ? '14px' : '16px'} color={theme.palette.primary.default} textshadow={`2px 2px 4px ${theme.palette.background.default}`} textalign={'left'} fontweight={600}>
                        Total Amount Charged
                      </FreedomTypographyText>
                      <FreedomTypographyText fontsize={mobile360 ? '12px' : ismdScreen ? '14px' : '16px'} color={theme.palette.primary.disableplaceholder} textalign={'left'} fontweight={600}>
                        $ {FixedAfterTwoDigit1(+companyDocumentVerificationFee + +transactionFee)}
                      </FreedomTypographyText>
                    </Box>

                    <FreedomTypographyText fontsize="10px" color={theme.palette.primary.disableplaceholder} textalign={'left'}>
                      *Total Amount Charged = ( Company Setup + Fee )
                    </FreedomTypographyText>
                  </FeeWrapper>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4} mt={0.1} spacing={1}>
                <form>
                  <PaymentElement />
                </form>
              </Grid>
            </Grid>
          </StepWrapper>
        );
      case 1:
        return (
          <Grid item sm={12} lg={12}>
            <StepWrapper>
              {opentAlert && <FreedomAlert severity={'error'} message={openAlertMessage} opentAlert={opentAlert} setOpenAlert={setOpenAlert} />}
              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center">
                <Grid container spacing={1} item xs={12} sm={12} md={10} lg={8} xl={8}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <VoucherWrapper height={'100%'} bordercolor={'none'} padding={'0px'}>
                      <VoucherWrapper flexDirection={'column'} padding={'10px'} alignItems={'center'}>
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <FreedomTypographyText fontfamily="Poppins" fontsize={'16px'} lineheight={'24px'} fontweight={400} textalign={'left'}>
                            {' '}
                            Credit Card{' '}
                          </FreedomTypographyText>
                          <ArrowRightAltIcon />
                          <FreedomTypographyText fontfamily="Poppins" fontsize={'16px'} lineheight={'24px'} fontweight={400} textalign={'left'}>
                            {' '}
                            Freedom Account
                          </FreedomTypographyText>
                        </Box>
                      </VoucherWrapper>
                      <VoucherWrapper>
                        <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                          <FreedomTypographyText fontfamily="Poppins" fontsize={'12px'} lineheight={'18px'} fontweight={400}>
                            {' '}
                            Deposit{' '}
                          </FreedomTypographyText>
                          <FreedomTypographyText color={theme.palette.primary.placeholder} fontfamily="Poppins" fontsize={'12px'} fontweight={600}>
                            {' '}
                            $ {FixedAfterTwoDigit1(+companyDocumentVerificationFee)}{' '}
                          </FreedomTypographyText>
                        </VoucherWrapper>
                        <Box sx={{ width: '100%' }}>
                          <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
                        </Box>
                        <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                          <FreedomTypographyText fontfamily="Poppins" lineheight={'18px'} fontsize={'12px'} fontweight={400}>
                            {' '}
                            Fee{' '}
                          </FreedomTypographyText>
                          <FreedomTypographyText color={theme.palette.primary.placeholder} fontfamily="Poppins" fontsize={'12px'} fontweight={600}>
                            {' '}
                            ${FixedAfterTwoDigit1(+transactionFee)}
                          </FreedomTypographyText>
                        </VoucherWrapper>
                      </VoucherWrapper>
                      <VoucherWrapper height={'250px'} bordercolor={'none'} padding={'0px'}>
                        <FreedomCreditCard number={`**** **** **** ${cards?.last4}`} name={' '} expiry={formatExpirationDate(`${cards?.expiryMonth}/${cards?.expiryYear}`)} issuer={cards?.brand} cvc={cards?.cvc} />
                      </VoucherWrapper>
                    </VoucherWrapper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <VoucherWrapper height={'100%'} padding={'8px'} justifyContent={'space-between'}>
                      <VoucherWrapper height={'100%'} bordercolor={'none'} padding={'0px'}>
                        <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                          <FreedomTypographyText fontfamily="Poppins" fontsize={'12px'} fontweight={400}>
                            {' '}
                            Email{' '}
                          </FreedomTypographyText>
                          <Title color={theme.palette.primary.paymentsummarycolor} fontsize={'12px'} fontweight={600} textalign={'right'}>
                            {email}
                          </Title>
                        </VoucherWrapper>
                        <Box sx={{ width: '100%' }}>
                          <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
                        </Box>
                        <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                          <FreedomTypographyText fontfamily="Poppins" fontsize={'12px'} fontweight={400}>
                            {' '}
                            Transaction Date{' '}
                          </FreedomTypographyText>
                          <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontfamily="Poppins" fontsize={'12px'} fontweight={600}>
                            {' '}
                            {currentDate()}
                          </FreedomTypographyText>
                        </VoucherWrapper>
                        <Box sx={{ width: '100%' }}>
                          <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
                        </Box>
                        <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                          <FreedomTypographyText fontfamily="Poppins" fontsize={'12px'} fontweight={400}>
                            {' '}
                            Card Number{' '}
                          </FreedomTypographyText>
                          <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontfamily="Poppins" fontsize={'12px'} fontweight={600}>
                            {' '}
                            **** **** **** {cards?.last4}{' '}
                          </FreedomTypographyText>
                        </VoucherWrapper>
                        <Box sx={{ width: '100%' }}>
                          <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
                        </Box>
                        <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                          <FreedomTypographyText fontfamily="Poppins" fontsize={'12px'} fontweight={400}>
                            {' '}
                            Payment Method{' '}
                          </FreedomTypographyText>
                          <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontfamily="Poppins" fontsize={'12px'} fontweight={600}>
                            {' '}
                            {capitalizeFullName(`${cards?.funding} Card`)}{' '}
                          </FreedomTypographyText>
                        </VoucherWrapper>
                        <Box sx={{ width: '100%' }}>
                          <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
                        </Box>
                        <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                          <FreedomTypographyText fontfamily="Poppins" fontsize={'12px'} fontweight={400}>
                            {' '}
                            Expiry Month{' '}
                          </FreedomTypographyText>
                          <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontfamily="Poppins" fontsize={'12px'} fontweight={600}>
                            {' '}
                            {cards?.expMonth}{' '}
                          </FreedomTypographyText>
                        </VoucherWrapper>
                        <Box sx={{ width: '100%' }}>
                          <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
                        </Box>
                        <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                          <FreedomTypographyText fontfamily="Poppins" fontsize={'12px'} fontweight={400}>
                            {' '}
                            Expiry Year{' '}
                          </FreedomTypographyText>
                          <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontfamily="Poppins" fontsize={'12px'} fontweight={600}>
                            {' '}
                            {cards?.expYear}{' '}
                          </FreedomTypographyText>
                        </VoucherWrapper>
                      </VoucherWrapper>
                      <VoucherWrapper flexDirection={'row'} justifyContent={'space-between'} padding={'10px'} alignItems={'center'}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <FreedomTypographyText fontfamily="Poppins" fontsize={'12px'} lineheight={'18px'} fontweight={400} textalign={'left'}>
                            {' '}
                            Total Amount Charged{' '}
                          </FreedomTypographyText>
                          <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontfamily="Poppins" fontsize={'10px'} lineheight={'14px'} fontweight={400} textalign={'left'}>
                            {' '}
                            Total Amount Charged =(Deposit + Fee)
                          </FreedomTypographyText>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <FreedomTypographyText fontfamily="Poppins" fontsize={'14px'} lineheight={'18px'} fontweight={700} color={theme.palette.primary}>
                            {' '}
                            $ {FixedAfterTwoDigit1(+(companyDocumentVerificationFee + +transactionFee))}
                          </FreedomTypographyText>
                        </Box>
                      </VoucherWrapper>
                    </VoucherWrapper>
                  </Grid>
                </Grid>
              </Grid>
            </StepWrapper>
          </Grid>
        );
      case 2:
        return (
          <Grid item sm={12} lg={12}>
            <StepWrapper>
              <Grid container item justifyContent="center" height={'100%'} alignItems={'center'}>
                <Grid item sm={10} md={8}>
                  <Grid container justifyContent="center" spacing={2} sx={{ mb: 8 }}>
                    <Grid container item sm={12} md={12} justifyContent="center">
                      <FreedomSuccessImage />
                    </Grid>
                    <Grid item sm={12} md={12}>
                      <FreedomDialogTypography fontsize={'24px'} fontweight={500} lineheight={'36px'} textalign={'center'} whiteSpace={'wrap'} color={theme.palette.text.primary}>
                        Submitted Successfully
                      </FreedomDialogTypography>
                    </Grid>
                    <Grid item sm={12} md={10}>
                      <Para fontsize={'12px'} lineheight={'18px'}>
                        Thank you for your company profile submission. Please ensure you've submitted it via email, as approval may take time due to document verification. We appreciate your patience as we work on getting your company onboarded. For
                        inquiries, contact support@myfreedom.ae.
                      </Para>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </StepWrapper>
          </Grid>
        );
      default:
        return 'Unknown step';
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
          padding: '8px'
        }
      }}
    >
      <Layout>
      <TopNavBar1 />
      {open && <FreedomSnackBar />}
      {/* <Box
        className={mode === 'light' ? classes.AppLight : classes.AppDark}
        component="main"
        style={{ marginTop: '70px' }}
        sx={{
          mb: '32px',
          transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.easeIn,
            duration: '.2s'
          })
        }}
      > */}
        <>
          <Grid container justifyContent="center" alignItems={'center'}>
            <Grid item xs={12} sm={12} lg={6} md={6}>
              <Stepper alternativeLabel activeStep={activeStep} className={classes.connector}>
                {steps.map((step, index) => (
                  <Step key={step.id} className={classes.stepWrap}>
                    <StepLabel StepIconComponent={ColorlibStepIcon} className={classes.labelhandle}>
                      {/* {t(step.label)} */}
                      {/* {lab = step.label}  */}
                      {/* {setLabel(step.label)} */}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              {activeStep === 0 ? (
                <Grid item sx={{ textAlign: 'center', margin: '16px 0px -5px 0px' }} lg={12} md={12}>
                  <FreedomTypography variant="h6"> {steps[0].label} </FreedomTypography>
                </Grid>
              ) : activeStep === 1 ? (
                <Grid item sx={{ textAlign: 'center', margin: '16px 0px -5px 0px' }} lg={12} md={12}>
                  <FreedomTypography variant="h6"> {steps[1].label} </FreedomTypography>
                </Grid>
              ) : activeStep === 2 ? (
                <Grid item sx={{ textAlign: 'center', margin: '16px 0px -5px 0px' }} lg={12} md={12}>
                  <FreedomTypography variant="h6"> {steps[2].label} </FreedomTypography>
                </Grid>
              ) : activeStep === 3 ? (
                <Grid item sx={{ textAlign: 'center', margin: '16px 0px -5px 0px' }} lg={12} md={12}>
                  <FreedomTypography variant="h6"> {steps[3].label} </FreedomTypography>
                </Grid>
              ) : (
                activeStep === 4 && (
                  <Grid item sx={{ textAlign: 'center', margin: '16px 0px -5px 0px' }} lg={12} md={12}>
                    <FreedomTypography variant="h6"> {steps[4].label} </FreedomTypography>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
          <Box sx={{ width: '100%' }} className={classes.removeBorder}>
            <Stepper activeStep={activeStep} orientation="vertical" className={classes.stapingCardBalance}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepContent
                   sx={{
                    margin: '0px!important',
                    padding: '0px!important'
                  }}
                  className={classes.removeBorder}
                  >
                    {getStepContent(index)}
                    <Box sx={{ mb: 2 }}>
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={6} md={2}>
                          <FreedomButton1 onClick={handleNext} sx={{ mt: 1, mr: 1 }} btnbackground={theme.palette.button.buttonbgcolor} variant="contained" loading={loading}>
                            {activeStep === steps.length - 1 ? `${t('Done')}` : activeStep === 0 ? 'Pay Now' : `${t('Next')}`}
                          </FreedomButton1>
                        </Grid>
                      </Grid>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>
        </>
        </Layout>
      {/* </Box> */}
      </Box>
  );
}

export default BusinessOnboardFee;
