import { styled } from '@mui/styles';
import React, { useContext } from 'react';
import { Box, Grid, useTheme } from '@mui/material';

import { ColorModeContext } from '../../store';
import { images, paymentsvg, upgradeAccount } from '../../url/SvgLinks';
import { FixedAfterTwoDigit1 } from '../../utils/utilityFunctions';
import FreedomFollowUpIcon from '../../Components/SharedComponent/FreedomFollowUpIcon/FreedomFollowUpIcon';

const platinumicon = images.platinum;
const silvericon = images.silver;
const goldicon = images.gold;
const silver = paymentsvg?.silver;
const gold = paymentsvg?.gold;
const platinum = paymentsvg?.platinum;
const sliverTogold = paymentsvg?.sliverTogold;
const goldToplatinum = paymentsvg?.goldToplatinum;
const slivertoplatinum = paymentsvg?.slivertoplatinum;
const silverDark = paymentsvg?.silverDark;
const goldDark = paymentsvg?.goldDark;
const platinumDark = paymentsvg?.platinumDark;
const sliverTogoldDark = paymentsvg?.sliverTogoldDark;
const goldToplatinumDark = paymentsvg?.goldToplatinumDark;
const slivertoplatinumDark = paymentsvg?.slivertoplatinumDark;
const exclusive = upgradeAccount.ExclusiveAccountTypeIcon;
const vip = upgradeAccount.VipAccountTypeIcon;
const ExclusiveDarkBg = upgradeAccount.ExclusiveDarkBg;
const ExclusiveLightBg = upgradeAccount.ExclusiveLightBg;
const VipDarkBg = upgradeAccount.VipDarkBg;
const VipLightBg = upgradeAccount.VipLightBg;
const VoucherCardTitle = styled('h3')(({ theme, ischecked, mode, hover, selected }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '35px'
}));

const VoucherCardPrice = styled('h3')(({ theme, ischecked, mode, hover, selected }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '36px',
  lineHeight: '54px',
  [theme.breakpoints.down('sm')]: {
    fontWeight: '600',
    fontSize: '32px',
    lineHeight: '48px'
  }
}));

const AccountCard = (props) => {
  const theme = useTheme();
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);
  const { accountType, price, title } = props;
  const cardImage =
    mode === 'dark'
      ? accountType == 2
        ? silverDark
        : accountType == 3
        ? goldDark
        : accountType == 4
        ? platinumDark
        : accountType == 6
        ? sliverTogoldDark
        : accountType == 7
        ? slivertoplatinumDark
        : accountType == 8
        ? goldToplatinumDark
        : accountType == 9
        ? ExclusiveDarkBg
        : accountType == 10
        ? VipDarkBg
        : ''
      : accountType == 2
      ? silver
      : accountType == 3
      ? gold
      : accountType == 4
      ? platinum
      : accountType == 6
      ? sliverTogold
      : accountType == 7
      ? slivertoplatinum
      : accountType == 8
      ? goldToplatinum
      : accountType == 9
      ? ExclusiveLightBg
      : accountType == 10
      ? VipLightBg
      : '';
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        borderRadius: '10px',
        backgroundSize: 'cover',
        display: 'flex',
        backgroundImage: `url(${cardImage})`,
        backgroundRepeat: 'no-repeat',
        padding: '8px'
      }}
    >
      <Grid container spacing={2} direction="column" justifyContent="space-between" alignItems="center">
        <Grid item container direction="row">
          <Grid item>
            <FreedomFollowUpIcon
              backgroundcolor={theme.palette.background.default}
              disabled
              bordercolor={theme.palette.border.containerborder}
              iconimg={accountType == 2 ? silvericon : accountType == 3 || accountType == 6 ? goldicon : accountType == 4 || accountType == 8 || accountType == 7 ? platinumicon : accountType == 9 ? exclusive : accountType == 10 ? vip : ''}
              borderradius="12px"
              width={'36px'}
              height={'36px'}
              responsiveheight={'50px'}
              responsivewidth={'50px'}
              responsiveborderrad={'12px'}
              imageheight={'20px'}
              imagewidth={'22px'}
              // imageheight={(accountType == 9 || accountType == 10) && '20px'}
              // imagewidth={(accountType == 9 || accountType == 10) && '13px'}
            />
          </Grid>
          <Grid item ml={2}>
            <VoucherCardTitle mode={mode}>{title}</VoucherCardTitle>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <VoucherCardPrice mode={mode}>${FixedAfterTwoDigit1(+price)}</VoucherCardPrice>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountCard;
