import { createSlice } from '@reduxjs/toolkit';
import { devConsoleLog } from '../../utils/utilityFunctions';

const initialState = {
  loading: true,
  error: '',
  data: []
};

export const wizardSlice = createSlice({
  name: 'wizard',
  initialState,
  reducers: {
    setWizardData: (state, action) => {
      devConsoleLog('data coming from wizard', action);

      let payload = action.payload;
      return {
        ...state,
        data: payload?.data
      };
    },
    setWizardLoading: (state, action) => {
      state.loading = action.payload;
    },
    setWizardError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { setWizardData, setWizardLoading, setWizardError } = wizardSlice.actions;

export default wizardSlice.reducer;
