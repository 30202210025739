import { Grid, Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';
import useStyle, { ColorlibStepIcon } from '../../../../Components/Style';
import { paymentAccountType } from '../../../../FreedomConstants/freedomConstants';
import { FreedomTypography } from '../../../../Components/SharedComponent/FreedomTypography/FreedomTypography';
// import { ColorlibStepIcon } from '../../UpgradeProfile1';

const StepperLable = ({ steps, activeStep, paymentPayMethod }) => {
  const classes = useStyle();
  return (
    <Grid container justifyContent="center" alignItems={'center'}>
      <Grid item xs={12} sm={12} lg={4} md={4}>
        <Stepper alternativeLabel activeStep={activeStep} className={classes.connector}>
          {steps?.map((step, index) => (
            <Step key={step.id} className={classes.stepWrap}>
              <StepLabel StepIconComponent={ColorlibStepIcon} className={classes.labelhandle}>
                {/* {t(step.label)} */}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
        {activeStep === 0 ? (
          <Grid item sx={{ textAlign: 'center', margin: '16px 0px -5px 0px' }} lg={12} md={12}>
            <FreedomTypography variant="h6"> {steps[0]?.label} </FreedomTypography>
          </Grid>
        ) : activeStep === 1 ? (
          <Grid item sx={{ textAlign: 'center', margin: '16px 0px -5px 0px' }} lg={12} md={12}>
            <FreedomTypography variant="h6">{steps[1]?.label}</FreedomTypography>
          </Grid>
        ) : activeStep === 2 ? (
          <Grid item sx={{ textAlign: 'center', margin: '16px 0px -5px 0px' }} lg={12} md={12}>
            <FreedomTypography variant="h6"> {paymentPayMethod == paymentAccountType.bankAccount ? steps[2]?.label1 : steps[2]?.label} </FreedomTypography>
          </Grid>
        ) : activeStep === 3 ? (
          <Grid item sx={{ textAlign: 'center', margin: '16px 0px -5px 0px' }} lg={12} md={12}>
            <FreedomTypography variant="h6"> {steps[3]?.label} </FreedomTypography>
          </Grid>
        ) : activeStep === 4 ? (
          <Grid item sx={{ textAlign: 'center', margin: '16px 0px -5px 0px' }} lg={12} md={12}>
            <FreedomTypography variant="h6"> {steps[4]?.label} </FreedomTypography>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  );
};

export default StepperLable;
