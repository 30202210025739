import React from 'react';
import VoucherCard from '../VoucherCard';
import { Loader } from '../../../Components/SharedComponent/FreedomLoader/FreedomLoader';
import { Box, Grid } from '@mui/material';
import { FixedAfterTwoDigit1 } from '../../../utils/utilityFunctions';
import { useTheme } from '@mui/styles';
import { UpgradeProfileStepWrapper } from '../UpgradeProfile1';

/**
 * Add Credit card Or bank account information Stepper
 */
const upgradedata = [
  [
    {
      title: 'Expected Annual',
      value: 'ROI (Upto) 14% To 25%'
    },
    {
      title: 'Investment From',
      value: `$${FixedAfterTwoDigit1(300, 0)} To $${FixedAfterTwoDigit1(1000, 0)}`
    },
    {
      title: 'Risk Profile',
      value: '12% To 30%'
    }
  ],
  [
    {
      title: 'Expected Annual',
      value: 'ROI (Upto) 40%'
    },
    {
      title: 'Investment From',
      value: `$${FixedAfterTwoDigit1(2000, 0)}`
      // value: 'ROI (Upto) $2,000.00',
    },
    {
      title: 'Risk Profile',
      value: '50%'
    }
  ],
  [
    {
      title: 'Expected Annual',
      value: 'ROI (Upto) 50%'
    },
    {
      title: 'Investment From',
      value: `$${FixedAfterTwoDigit1(2000, 0)}`
    },
    {
      title: 'Risk Profile',
      value: '50%'
    }
  ]
];

const UpgradeProfileFirstStep = ({ loading, data, callback, selectedVoucher }) => {
  const theme = useTheme();
  return (
    <Grid container item xs={12} sm={12} lg={12} >
      <UpgradeProfileStepWrapper>
        {/* <Box margin={'auto'} width={'100%'}> */}
          <Grid container spacing={2}>
            {loading ? (
              <Loader />
            ) : (
              data.length > 0 &&
              data?.map((item, index) => (
                <Grid key={item?.id} item gap={2}>
                  <VoucherCard
                    item={item}
                    id={item?.id}
                    clickOnCard={() => {
                      callback(data, item);
                    }}
                    ischecked={item?.id == selectedVoucher}
                    title={item?.VoucherName}
                    price={item?.Price}
                    description={item?.Description}
                    features={item?.Features}
                    product={upgradedata[index]}
                  />
                </Grid>
              ))
            )}
          </Grid>
        {/* </Box> */}
      </UpgradeProfileStepWrapper>
    </Grid>
  );
};

export default UpgradeProfileFirstStep;
