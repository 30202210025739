import { countSkip, productScreenId } from '../../FreedomConstants/freedomConstants';
import { CTraderAccountReconciliation } from '../../utils/tradingConfig';
import { getBaseUrl } from '../../utils/userConfig';

const baseUrl = getBaseUrl();
const siteUrl = getBaseUrl();

export const getProductsUrl = () => {
  return `${baseUrl}Products`;
};

export const getProductsByTypeUrl = (ProductTypeId) => {
  return `${baseUrl}Products?filter=((AccountTypeId eq ${ProductTypeId}))`;
};
export const getTradeCountAndPerformancedUrl = () => {
  return `${baseUrl}GetInvestmentModuleTradeCountsAndPerformancePercentage`;
};
export const getWinningAndLosingTradesUrl = (month, year, productid = 0) => {
  return `${baseUrl}GetInvestmentModuleWinningAndLosingTrades?month=${month}&year=${year}&productid=${productid}`;
};

export const grtSubsctibedProducts = () => {
  return `${baseUrl}userproducts`;
};

export const getUserTradingBalance = () => {
  return `${baseUrl}getusertradingbalance`;
};
export const getUserTradingHistory = (page, countSkip, property, order, direction, searchedText, startDate, endDate, ctraderinfoid) => {
  if (searchedText === '') {
    return `${baseUrl}gettradehistory(${page},${countSkip},'${property}','${order}')?direction=${direction}&startDate=${startDate}&endDate=${endDate}&ctraderinfoid=${ctraderinfoid}`;
  }
  // if (direction !== "") {
  //   return `${baseUrl}gettradehistory(${page},${countSkip},'${property}','${order}')?direction=${direction}`;
  // } else if (
  //   (searchedText === "" && direction === "" && startDate !== "",
  //   endDate !== "")
  // ) {
  //   devConsoleLog("start date enddata", startDate, endDate);
  //   return `${baseUrl}gettradehistory(${page},${countSkip},'${property}','${order}')?startDate=${startDate}&endDate=${endDate}`;
  // } else if (direction === "") {
  //   return `${baseUrl}gettradehistory(${page},${countSkip},'${property}','${order}')`;
  // } else if (searchedText === "") {
  //   return `${baseUrl}gettradehistory(${page},${countSkip},'${property}','${order}')`;
  // }
  else {
    return `${baseUrl}searchtradehistory(${page},${countSkip},'${property}','${order}','${searchedText}')?direction=${direction}&startDate=${startDate}&endDate=${endDate}`;
  }
};

// export const getUserLiveTradingUrl = (page, countSkip, id, order) => {
//   return `${baseUrl}getlivetrades(${page},${countSkip},'${id}','${order}')`;
// };

// export const getUserLiveTradeUrl = (
//   page,
//   countSkip,
//   id,
//   order,
//   searchedText
// ) => {
//   if (searchedText === "") {
//     return `${baseUrl}getlivetrades(${page},${countSkip},'${id}','${order}')`;
//   } else {
//     return `${baseUrl}searchlivetrades(${page},${countSkip},'${id}','${order}','${searchedText}')`;
//   }
// };

// export const getSymbolPeriodsUrl = (
//   page,
//   countSkip,
//   id,
//   order,
//   searchedText
// ) => {
//   if (searchedText === "") {
//     return `${baseUrl}getsymbolperiods(${page},${countSkip},'${id}','${order}')`;
//   } else {
//     // return `${baseUrl}searchlivetrades(${page},${countSkip},'${id}','${order}','${searchedText}')`;
//   }
// };

// export const getUserLiveTradingSearchUrl = (
//   page,
//   countSkip,
//   id,
//   order,
//   searchedText,
//   direction,
//   startDate,
//   endDate
// ) => {
//   if (searchedText === "") {
//     return `${baseUrl}searchlivetrades(${page},${countSkip},'${id}','${order}')`;
//   } else {
//     return `${baseUrl}searchtradehistory(${page},${countSkip},'${id}','${order}','${searchedText}')?direction=${direction}&startDate=${startDate}&endDate=${endDate}`;
//   }
// };

export const getUnpaidBrokerInvoiceStatus = () => {
  return `${baseUrl}unpaidbrokerdepositinvoiceexists`;
};

export const getUserTradeStatus = () => {
  return `${baseUrl}getusertradestatus`;
};

export const getInvestmentModuleTradeCountsAndPerformancePercentage = (productid = 0) => {
  return `${baseUrl}getInvestmentModuleTradeCountsAndPerformancePercentage?productid=${productid}`;
};
export const liveTradeCountt = (userid) => {
  return `${CTraderAccountReconciliation()}/api/CTraderOpenPostions/GetOpenPostionsCount?UserId=${userid}`;
};
export const getInvestmentModule = (id) => {
  return `${CTraderAccountReconciliation()}/api/FreedomMyPlan/${id}`;
};

// export const getInvestmentLiveTradeCount = (id) => {
//   return `${CTraderAccountReconciliation()}api/CTraderOpenPostions/GetOpenPostionsCount?UserId=${id}`;
// };

export const getDlinkBroker = (ctradeId, id) => {
  return `${CTraderAccountReconciliation()}/api/FreedomMyPlan/UserAccountDLinking?CTradrId=${ctradeId}&UserId=${id}`;
};
export const getUserMonthlyROI = (id, productid, month = 1, year = 2024) => {
  return `${CTraderAccountReconciliation()}/api/FreedomInvestmentDashboard/UserMonthlyROIStream?id=${id}&productid=${productid}&month=${month}&year=${year}`;
};

//Api URL for get current rank
export const getCurrentRankApiUrl = (id) => {
  return `${baseUrl}GetUserCurrentRankData(${id})`;
};

export const mainBalanceAPIUrl = (graphPeriod, startDate, endDate, productid = 0) => {
  // return `${baseUrl}GetAffiliateDashboardData(${affiliateType},${graphPeriod})`
  return `${baseUrl}GetUserBalanceComponentData(${graphPeriod},${startDate},${endDate},${productid})`;
};

//Graph Data Url For Ivestor Main Dashboard

export const mainEquityAPIUrl = (graphPeriod, startDate, endDate) => {
  // return `${baseUrl}GetAffiliateDashboardData(${affiliateType},${graphPeriod})`
  return `${baseUrl}GetUserEquityComponentData(${graphPeriod},${startDate},${endDate})`;
};

//Graph Data Url For ROI Main Dashboard

export const mainRoiAPIUrl = (graphPeriod, startDate, endDate, productid) => {
  //productid = 0 is for overall Products ROI
  return `${baseUrl}GetUserROIComponentData(${graphPeriod},${startDate},${endDate},${productid})`;
};

//Graph Data Url For DrawDown Main Dashboard

export const mainDrawDownAPIUrl = (graphPeriod, startDate, endDate) => {
  // return `${baseUrl}GetAffiliateDashboardData(${affiliateType},${graphPeriod})`
  return `${baseUrl}GetUserDrawDownComponentData(${graphPeriod},${startDate},${endDate})`;
};

/**
 * TradeStaus
 * Active = 1
 * completed=3
 */
export const getUserSubscribedProducts = (lookriskmodeid = 0, looktradingstyleid = 0, productTypeId=null, search = '', userproductid = '', tradeStatus = 1, productScreenId=1) => {
  return `${CTraderAccountReconciliation()}/api/FreedomMyPlan/GetUserSubscribedProducts/?lookriskmodeid=${lookriskmodeid}&looktradingstyleid=${looktradingstyleid}&search=${search}&userproductid=${userproductid}&tradeStatus=${tradeStatus}&productTypeId=${productTypeId}&productScreenID=${productScreenId}`;
  // return `${CTraderAccountReconciliation()}/api/FreedomMyPlan/${id}`;
};

//Api URL for get user subscribed Product Graph
export const getProductGraph = (startDate, endDate, productId, userProductId = 0) => {
  return `${CTraderAccountReconciliation()}/api/ROI/GetUserROIComponentData?startDate=${startDate}&endDate=${endDate}&productId=${productId}&userProductId=${userProductId}`;
};

export const getProductOverviewGraph = (startDate, endDate, productId, userProductId = 0) => {
  return `${CTraderAccountReconciliation()}/api/ROI/WarehouseGetUserROIComponentDailyDateRange?startDate=${startDate}&endDate=${endDate}&productId=${productId}&userProductId=${userProductId}`;
}

//Api URL for get user subscribed Product Graph
export const getNotifications = () => {
  return `${CTraderAccountReconciliation()}/api/Notification/GetNotificationsByUserID`;
};

//Api URL for get favorite bar items and prefered header
export const getAllFavNavigationsByUser = (featuretype = 1) => {
  return `${baseUrl}Navigation/GetAllFavNavigationsByUser?featuretype=${featuretype}`;
};

//Api URL for get favorite bar items and prefered header
export const addDeleteFavoriteByUser = () => {
  return `${baseUrl}Navigation/AddDelete`;
};

//Api URL for get Active Products By UserID
export const getActiveProductsByUserID = () => {
  return `${CTraderAccountReconciliation()}/api/FreedomMyPlan/GetActiveProductsByUserID`;
};

//Api URL for live Trader Headers
export const getLiveTradeHeader = () => {
  return `${baseUrl}Navigation/GetLiveTradeModuleHeaders`;
};

//Api URL for Trader History Headers
export const getTradeHistoryHeader = () => {
  return `${baseUrl}Navigation/GetTradeHistoryModuleHeaders`;
};

///Api URL for Manual ReInvest
export const getUserManualReInvestUrl = (ctraderinfoid, amounttoReinvest) => {
  return `${baseUrl}ManualReInvest?ctraderinfoid=${ctraderinfoid}&amounttoReinvest=${amounttoReinvest}`;
};

///Api URL for Change Reinvest Status
export const getUserChangeReinvestStatusUrl = (ctraderinfoid, reInvestAmountLimit, reInvestStatus) => {
  return `${baseUrl}ChangeReinvestStatus?ctraderinfoid=${ctraderinfoid}&reInvestAmountLimit=${reInvestAmountLimit}&reInvestStatus=${reInvestStatus}`;
};

//API URL for product recent Activity
export const getProductRecentActivity = (ctraderId = 0, userProductId = '', pageNumber = 1) => {
  return `${CTraderAccountReconciliation()}/api/ActivityLog/GetLogsByCtraderAndUserProductID?ctraderId=${userProductId}&userProductId=${ctraderId}&pageNumber=${pageNumber}&rowOfPage=${countSkip}`;
};

export const getStartedWizardAPI = () => {
  return `${baseUrl}Wizard/GetWizardStep`;
};
